import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Row, Col, Container } from 'reactstrap';
import fp from 'lodash/fp';
import { lifecycle, defaultProps } from 'recompose';
import Title from '../../../shared/components/Title';
import Section from '../../../shared/components/Section';
import { debouncedShowNotification as showNotification, destroyNotification,
  instantiateNotification } from '../../../shared/components/notification/';
import { BasicNotification } from '../../../shared/components/Notification';
import store from '../../../redux/store';

import validate from './validate';


import {
  Form,
  FormGroup,
  Label,
  FormField
} from '../../../shared/components/Form';
import RenderField from '../../../shared/components/form/RenderField';


const UserForm = ({
  handleSubmit,
  submitting,
  submitSucceeded,
  submitFailed,
  onSuccessSubmit,
  initialValues,
  errorResponse,
  currentUser
}) => {
  const notify = (color, title, message, position = 'right-up') => {
    showNotification({
      notification: <BasicNotification
        color={color}
        title={title}
        message={message}
      />,
      position
    });
  };

  const getUserLevel = fp.flow(
    fp.get('userRoles'),
    fp.map('level')
  );

  if (submitSucceeded) {
    notify('success', 'Success', 'User Successfully Editted');
    onSuccessSubmit();
  }

  const isActiveStatuses = ['true', 'false'];

  return (
    <Col xs={6} md={6} lg={6} xl={12}>
      {/* TODO: put the actual error message */}
      {submitFailed ?
        notify('danger', 'Unsuccessful', errorResponse.message) :
        ''}
      <Section>
        <Title
          title="User Details"
          subhead={submitting ? 'submitting...' : 'Edit data on the form.'}
        />
        <Form className="form" orientation="vertical" onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col md={8} xs={8} lg={8} xl={8}>
                <FormGroup>
                  <Label>First Name: </Label>
                  <FormField
                    name="firstName"
                    component={RenderField}
                    type="text"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Last Name: </Label>
                  <FormField
                    name="lastName"
                    component={RenderField}
                    type="text"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>E-mail: </Label>
                  <FormField
                    name="email"
                    component={RenderField}
                    type="text"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Status: </Label>
                  <FormField
                    name="isActive"
                    className="form__form-group-select"
                    style={{ width: 200 }}
                    component="select"
                  >
                    {isActiveStatuses.map(userStatusOption =>
                      ((userStatusOption !==
                        fp.get('isActive', initialValues)) ?
                          <option
                            value={userStatusOption}
                            key={userStatusOption}
                          >
                            { userStatusOption === 'true' ? 'active' : 'inactive'}
                          </option> :
                          <option
                            value={userStatusOption}
                            key={userStatusOption}
                          >
                            { userStatusOption === 'true' ? 'active' : 'inactive'}
                          </option>))}
                  </FormField>
                </FormGroup>
                {fp.includes(5, getUserLevel(currentUser)) ||
                fp.get('id', currentUser) === fp.get('id', initialValues) ?
                  <FormGroup>
                    <Label>Reset Password: </Label>
                    <FormField
                      name="password"
                      component={RenderField}
                      type="password"
                    />
                  </FormGroup>
                : ''}
              </Col>
            </Row>
          </Container>
        </Form>
      </Section>
    </Col>
  );
};

UserForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  onSuccessSubmit: PropTypes.func,
  initialValues: PropTypes.shape({}),
  errorResponse: PropTypes.shape({ message: PropTypes.string }),
  currentUser: PropTypes.shape({}).isRequired
};

UserForm.defaultProps = {
  onSuccessSubmit: () => {},
  initialValues: {},
  errorResponse: {}
};

const mapDispatch = (dispatch, ownProps) => ({
  fetchUser: dispatch.user.fetchUser,
  onSubmit: dispatch.user.updateUser,
  onSuccessSubmit: () => ownProps.history.push('/admin/user-management/list')
    && destroyNotification(),
  submitFailed: () => destroyNotification()
});

const mapStateToProps = (state, ownProps) => {
  const id = fp.get('match.params.id', ownProps);
  const userDetails = store.select.user.getUser(id)(state);
  const initialValues = fp.omit('password')(userDetails);

  return {
    errorResponse: store.select.user.error(state),
    currentUser: store.select.user.currentUser(state),
    initialValues,
    id
  };
};

export default fp.flow(
  lifecycle({
    componentDidMount() {
      instantiateNotification();
      const id = fp.get('id')(this.props);
      this.props.fetchUser(id);
    }
  }),
  defaultProps({
    fetchUser: () => {}
  }),
  reduxForm({
    form: 'user_form',
    validate
  }),
  connect(
    mapStateToProps,
    mapDispatch
  )
)(UserForm);
