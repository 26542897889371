import { reducer as reduxFormReducer } from 'redux-form';
import { init } from '@rematch/core';
import selectPlugin from '@rematch/select';
import createLoadingPlugin from '@rematch/loading';
import createRematchPersist from '@rematch/persist';

import user from './models/user';
import callRecordings from './models/call-recordings';
import callRecordingTracker from './models/call-recording-tracker';
import callRecordingActions from './models/call-recording-actions';
import callRecordingTags from './models/call-recording-tags';
import filterPanel from './models/filter-panel';
import globalConfig from './models/global-config';
import dataField from './models/data-field';

import { sidebarReducer, themeReducer, rehydrateReducer } from './reducers/index';

const persistPlugin = createRematchPersist({
  whitelist: ['user', 'globalConfig'], // persist these models
  throttle: 1000,
  version: 1
});

const store = init({
  redux: {
    reducers: {
      form: reduxFormReducer, // mounted under "form",
      theme: themeReducer,
      sidebar: sidebarReducer,
      rehydrate: rehydrateReducer
    }
  },
  models: {
    user,
    callRecordings,
    callRecordingTracker,
    callRecordingActions,
    callRecordingTags,
    filterPanel,
    globalConfig,
    dataField
  },
  plugins: [selectPlugin(), createLoadingPlugin({}), persistPlugin]
});

export const { select } = store;

export default store;
