import fp from 'lodash/fp';
import {
  getDateLengthInSeconds,
  composeCallRecordingAction
} from './helpers';
import { select } from '../store';
import * as callRecordingTagService from '../../services/call-recording-tag';

const defaultState = {
  all: {}
};

export default {
  state: defaultState,

  reducers: {
    setCallRecordingTags(state, response, callRecording) {
      const { startTime: recordingStartTime } = callRecording;
      const callRecordingId = fp.flow(
        fp.head,
        fp.get('callRecordingId')
      )(response);

      return fp.assign(state, {
        all: {
          ...state.all,
          [callRecordingId]: fp.flow(
            fp.map(composeCallRecordingAction),
            fp.map((action) => {
              const startTime = fp.get('startTime', action);
              const name = fp.get('name', action);

              const startLengthInSecs = getDateLengthInSeconds(
                recordingStartTime,
                startTime
              );
              const endLengthInSecs = startLengthInSecs + 0.1;
              return {
                start: startLengthInSecs,
                end: endLengthInSecs,
                name
              };
            })
          )(response)
        }
      });
    }
  },

  effects: {
    async fetchCallRecordingTags(callRecordingId, state) {
      const callRecording = select
        .callRecordings.getCallRecording(callRecordingId)(state);

      const queryParams = {
        call_recording_id: {
          in: [callRecordingId]
        }
      };

      const callRecordingTag = await callRecordingTagService
        .fetchCallRecordingTags(queryParams);

      this.setCallRecordingTags(callRecordingTag, callRecording);
    }
  },

  selectors: (slice, createSelector, hasProps) => ({
    all() {
      return createSelector(
        slice,
        fp.get('all')
      );
    },
    getTagsForCallRecordingId: hasProps((models, callRecordingId) =>
      slice(fp.flow(
        fp.get('all'),
        fp.get(callRecordingId)
      )))
  })
};
