import fp from 'lodash/fp';
import { getFormValues } from 'redux-form';
import { getSelectedProcess } from './helpers';
import columnDef from '../../components/CallRecordings/List/columnsDef';


const defaultState = {
  addedFilter: [],
  processValue: {},
  isFilterExpanded: true,
  player: {
    isFastModeEnabled: true
  },
  viewedCallRecordings: [],
  callRecordingColumnsByProcessId: {},
  isPlaybackToEnd: false
};

export default {
  state: defaultState,

  reducers: {
    setIsFilterExpanded(state, value) {
      return fp.merge(state, {
        isFilterExpanded: value
      });
    },
    setFastMode(state, isFastModeEnabled) {
      return fp.merge(state, {
        player: {
          ...state.player,
          isFastModeEnabled
        }
      });
    },
    setCallRecordingToViewed(state, id) {
      return fp.merge(state, {
        viewedCallRecordings: fp.uniq([...state.viewedCallRecordings, fp.toNumber(id)])
      });
    },
    addFieldToShownList(state, processId, columns = {}) {
      const uniqueByKey = fp.uniqBy('key');
      return fp.merge(state, {
        callRecordingColumnsByProcessId: {
          ...state.callRecordingColumnsByProcessId,
          [processId]: uniqueByKey([
            ...columnDef,
            ...(state.callRecordingColumnsByProcessId[processId] || []),
            columns
          ])
        }
      });
    },
    removeFieldFromShownList(state, processId, columns = {}) {
      const { callRecordingColumnsByProcessId } = state;
      return fp.assign(state, {
        callRecordingColumnsByProcessId: {
          ...callRecordingColumnsByProcessId,
          [processId]: fp.reject(columns)(callRecordingColumnsByProcessId[processId])
        }
      });
    },
    loadFilter(state, response) {
      const filters = fp.map(filter => ({
        value: filter.value,
        label: filter.label
      }))(response.addFilter);

      return fp.assign(state, { addedFilter: filters });
    },
    setProcessValue(state, value) {
      const processValue = fp.omit(['preventDefault'], value);
      return fp.assign(state, { processValue });
    },
    setIsPlaybackToEnd(state, isPlaybackToEnd) {
      return fp.assign(state, {
        isPlaybackToEnd
      });
    },
    clearAddedFilter(state) {
      return fp.assign(state, {
        addedFilter: defaultState.addedFilter
      });
    },
    clearSelectedProcess(state) {
      return fp.assign(state, {
        processValue: defaultState.processValue
      });
    },
    clearAll() {
      return defaultState;
    },
    clearProcessValue() {
      return defaultState;
    }
  },

  effects: {
    toggleFastMode(isFastModeEnabled/* , state */) {
      this.setFastMode(!isFastModeEnabled);
    },
    handleViewedCallRecording(id) {
      this.setCallRecordingToViewed(id);
    },
    addCallRecordingColumnHandler(processId, _, column) {
      this.addFieldToShownList(processId, column);
    },
    removeCallRecordingColumnHandler(processId, _, column = {}) {
      this.removeFieldFromShownList(processId, column);
    },
    resetCallRecordingColumnHandler() {
      this.resetCallRecordingColumns();
    },
    addFilter(value, state) {
      const filterValues = fp.flow(
        getFormValues('call_recording_filter_form'),
        (formValues) => {
          const addFilter = fp.get('add_filter', formValues);

          return { addFilter };
        }
      )(state);

      this.loadFilter(filterValues);
    },
    storeSelectedProcessValue(value) {
      this.setProcessValue(value);
    },
    resetAddedFilter() {
      this.clearAddedFilter();
    },
    resetSelectedProcess() {
      this.clearSelectedProcess();
    },
    storeIsExpandedState(value) {
      this.setIsFilterExpanded(value);
    },
    setPlayback(isPlaybackAtEndTime) {
      this.setIsPlaybackToEnd(isPlaybackAtEndTime);
    }
  },

  selectors: (slice, createSelector) => ({
    player() {
      return createSelector(
        slice,
        fp.get('player')
      );
    },
    isFastModeEnabled() {
      return createSelector(
        this.player,
        player => fp.get('isFastModeEnabled')(player)
      );
    },
    getCallRecordingColumns() {
      return createSelector(
        slice,
        fp.get('callRecordingColumnsByProcessId')
      );
    },
    getCallRecordingColumnsByProcessId() {
      return createSelector(
        (state, processId) => fp.flow(
          this.getCallRecordingColumns,
          fp.getOr(columnDef, processId)
        )(state),
        shownColumns => shownColumns
      );
    },
    getCallRecordingColumnsBySelectedProcess() {
      return createSelector(
        state => fp.flow(
          this.getCallRecordingColumns,
          fp.getOr(columnDef, getSelectedProcess(state))
        )(state),
        shownColumns => shownColumns
      );
    },
    addedFilterValue() {
      return createSelector(
        slice,
        fp.get('addedFilter')
      );
    },
    selectedProcessValue() {
      return createSelector(
        slice,
        fp.get('processValue')
      );
    },
    getIsFilterExpandedState() {
      return createSelector(
        slice,
        fp.get('isFilterExpanded')
      );
    },
    getIsPlaybackToEndValue() {
      return createSelector(
        slice,
        fp.get('isPlaybackToEnd')
      );
    }
  })
};
