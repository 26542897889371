import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal } from 'reactstrap';

export default class ConfirmationModalComponent extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
    onConfirmClick: PropTypes.func,
    onCancelClick: PropTypes.func
  };

  static defaultProps = {
    title: '',
    message: '',
    onConfirmClick: () => { },
    onCancelClick: () => { }
  };

  render() {
    const {
      title, message
    } = this.props;

    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          className="modal-dialog--danger"
        >
          <div className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              onClick={this.props.onCancelClick}
            />
            <span className="lnr lnr-warning modal__title-icon" />
            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">{message}</div>
          <ButtonToolbar className="modal__footer">
            <Button outline onClick={this.props.onCancelClick}>Cancel</Button>
            <Button outline color="danger" onClick={this.props.onConfirmClick}>
              Confirm
            </Button>
          </ButtonToolbar>
        </Modal>
      </div>
    );
  }
}
