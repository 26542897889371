import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import fp from 'lodash/fp';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import queryString from 'qs';

import {
  CallRecordingTags,
  CallRecordingDetails,
  Player
} from '../../components/CallRecordings/Show/';

const CallRecordingShow = ({ location, match }) => {
  const { time } = queryString.parse(location.search, { ignoreQueryPrefix: true });
  const startTime = Number(time) || 0;
  const callRecordingId = Number(fp.get('params.id', match));

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title" style={{ paddingBottom: '1rem' }}>
            <Link className="back__link" to="/dashboard">
              <span className="back__link-icon lnr lnr-arrow-left" />
              <span className="back__link-title"> Back </span>
            </Link>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col xl={3} lg={12} md={3} sm={12}>
          <CallRecordingDetails callRecordingId={callRecordingId} />
          <CallRecordingTags id={callRecordingId} currentTime={startTime} />
        </Col>
        <Col xl={9} lg={12} md={9} sm={12}>
          <Player callRecordingId={callRecordingId} startTime={startTime} />
        </Col>
      </Row>
    </Container>
  );
};

CallRecordingShow.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    })
  }).isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.string
  }).isRequired
};

export default withRouter(CallRecordingShow);
