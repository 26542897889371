import React from 'react';
import PropTypes from 'prop-types';
import EqualIcon from 'mdi-react/EqualIcon';
import { reduxForm } from 'redux-form';
import fp from 'lodash/fp';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import {
  FormGroup,
  Label,
  FormGroupField,
  FormField
} from '../../../../shared/components/Form';
import KSelect from '../../../../shared/components/KSelect';
import store from '../../../../redux/store';
import { getSelectedLength, getLoadingMessage } from '../../../../redux/models/helpers';

const DataField = ({
  dataFieldsHasFetched,
  dataFields,
  dataFieldValues,
  fetchDataFieldValues,
  isDataFieldValuesLoading,
  resetDataFieldDropDownValues,
  onFilter
}) => {
  const handleDataFieldValuesOnChange = (key, value) => {
    const selectedDataFieldLength = getSelectedLength(value);

    if (selectedDataFieldLength === 0) {
      fetchDataFieldValues(key, value);
    }

    onFilter(value);
  };

  const handleDataFieldValuesBlur = (event) => {
    event.preventDefault();
    if (!fp.isEmpty(event.target.value)) {
      /*
        TODO: handle reset for each data field.
        Current resets all the data field dropdown values
       */
      resetDataFieldDropDownValues();
    }
  };

  const renderFilters = (fields = []) =>
    fields.map(field => (
      <Col lg={2} md={4} xs={6} key={field.value}>
        <FormGroup>
          <Label>{field.label}</Label>
          <FormGroupField>
            {/* TOFIX: THIS SHOULD BE temporary! ref:  ODF#1600 */}
            {/* {currentUser.isFiveStrataQA &&
             field.label.toLowerCase() === 'phone' ? ( */}
            {field.label.toLowerCase().includes('phone') ? (
                <>
                  <div className="form__form-group-icon">
                    <EqualIcon />
                  </div>
                  <FormField
                    name={`${field.value}[in]`}
                    component="input"
                    placeholder=""
                    onChange={value =>
                      handleDataFieldValuesOnChange(field.value, value)
                    }
                  />
                </>
              ) : (
                <FormField
                  className="form__form-group-select"
                  component={KSelect}
                  name={`${field.value}[in]`}
                  isMulti
                  isLoading={isDataFieldValuesLoading}
                  noResultsText={getLoadingMessage(
                    dataFieldsHasFetched,
                    dataFieldValues[field.value]
                  )}
                  options={dataFieldValues[field.value]}
                  onChange={value => handleDataFieldValuesOnChange(field.value, value)}
                  onSearch={value =>
                    fetchDataFieldValues(field.value, { value, type: 'ilike' })
                  }
                  onFocus={value =>
                    fp.isEmpty(dataFieldValues[field.value]) &&
                    fetchDataFieldValues(field.value, value)
                  }
                  onBlur={handleDataFieldValuesBlur}
                  placeHolder={`Select ${field.label}...`}
                />
              )}
          </FormGroupField>
        </FormGroup>
      </Col>
    ));

  return renderFilters(dataFields);
};

DataField.propTypes = {
  isDataFieldValuesLoading: PropTypes.bool.isRequired,
  onFilter: PropTypes.func.isRequired,
  resetDataFieldDropDownValues: PropTypes.func.isRequired,
  fetchDataFieldValues: PropTypes.func.isRequired,
  dataFieldsHasFetched: PropTypes.bool.isRequired,
  dataFieldValues: PropTypes.shape({}),
  dataFields: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }))
};

DataField.defaultProps = {
  dataFieldValues: {}
};

const mapDispatchToProps = dispatch => ({
  fetchDataFieldValues: dispatch.filterPanel.fetchDataFieldValues,
  resetDataFieldDropDownValues: dispatch.filterPanel.resetDataFieldValues
});

const mapStateToProps = (state) => {
  const selectedProcessValue = store.select.globalConfig.selectedProcessValue(state);
  const selectedFilterValues = store.select.globalConfig.addedFilterValue(state);

  // Set initial value for filters and process
  // Note: we did not use camelCase for initialValues because we need to match
  // to form name
  const initialValues = {
    process_id: fp.isEmpty(selectedProcessValue) ? null : selectedProcessValue,
    add_filter: selectedFilterValues
  };

  return {
    initialValues,
    dataFieldValues: store.select.filterPanel.getDataFieldValues(state),
    dataFieldsHasFetched: state.filterPanel.hasFetched.dataFields,
    isDataFieldValuesLoading: state.loading.effects.filterPanel
      .fetchDataFieldValues
  };
};

export default fp.flow(
  reduxForm({
    form: 'call_recording_filter_form',
    destroyOnUnmount: false
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DataField);
