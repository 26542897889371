import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

import Layout from './UserLayout/index';
import AuthenticatedRoute from '../components/HoC/AuthenticatedRoute/index';
import CallRecordingsRoute from './CallRecordings/Route';
import DashboardRoute from './Dashboard/Route';
import GenericErrorPage from '../components/GenericErrorPage/index';

// TODO: change this to a function component
class WrappedRoutes extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool
  };

  static defaultProps = {
    authenticated: false
  };

  componentDidMount() {}

  render() {
    const { authenticated } = this.props;

    return (
      <div>
        <Layout />
        <div className="container__full">
          <Switch>
            {
              <AuthenticatedRoute
                path="/dashboard"
                component={DashboardRoute}
                authenticated={authenticated}
                exact
              />
            }
            {
              <AuthenticatedRoute
                path="/call-recordings"
                component={CallRecordingsRoute}
                authenticated={authenticated}
              />
            }
            {
              <AuthenticatedRoute
                path="/403"
                component={() => (<GenericErrorPage
                  title="Forbidden"
                  statusCode="403"
                />)}
                authenticated={authenticated}
              />
            }
            {
              <AuthenticatedRoute
                path="/500"
                component={() => (<GenericErrorPage
                  title="Internal Server Error"
                  statusCode="500"
                />)}
                authenticated={authenticated}
              />
            }
            {
              <AuthenticatedRoute
                path="*"
                component={() => (<GenericErrorPage
                  title="Not Found"
                  statusCode="404"
                />)}
                authenticated={authenticated}
              />
            }
          </Switch>
        </div>
      </div>
    );
  }
}

export default WrappedRoutes;
