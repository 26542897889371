import React from 'react';
import * as PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import FilterManagementList from './List';

const AdminFilterManagementRoute = ({ match }) => (
  <Switch>
    <Route path={`${match.path}`} component={FilterManagementList} />
  </Switch>
);

AdminFilterManagementRoute.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired
};

export default AdminFilterManagementRoute;
