import React from 'react';
import * as PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import CallRecordingShowPage from './Show';
import CallRecordingList from './List';
import CallRecordingUUIDRouter from './Uuid';

const CallRecordingRoute = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={CallRecordingList} />
    <Route exact path={`${match.path}/:id`} component={CallRecordingShowPage} />
    <Route exact path={`${match.path}/uuid/:uuid`} component={CallRecordingUUIDRouter} />
  </Switch>
);

CallRecordingRoute.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired
};

export default CallRecordingRoute;
