import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { isSubmitting, submit as submitAction } from 'redux-form';

const SubmitButton = ({ submitting, submit }) => (
  <Button
    color="primary"
    className="btn btn-primary account__btn account__btn--small"
    onClick={() => submit('log_in_form')}
    disabled={submitting}
    type="submit"
  >
    Sign In
  </Button>
);

SubmitButton.propTypes = {
  submitting: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired
};

const mapState = state => ({
  submitting: isSubmitting('log_in_form')(state)
});

const mapDispatch = {
  submit: submitAction
};

export default connect(
  mapState,
  mapDispatch
)(SubmitButton);
