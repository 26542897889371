export default [
  {
    key: 'fileName',
    name: 'File Name'
  },
  {
    key: 'startTime',
    name: 'Call Date'
  },
  {
    key: 'agentName',
    name: 'Operator'
  },
  {
    key: 'callLengthSeconds',
    name: 'Call Length'
  },
  {
    key: 'clientWithProcessName',
    name: 'Process'
  }
];
