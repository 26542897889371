export default [
  {
    key: 'firstName',
    name: 'First Name'
  },
  {
    key: 'lastName',
    name: 'Last Name'
  },
  {
    key: 'email',
    name: 'Email'
  },
  {
    key: 'organization',
    name: 'Organization'
  },
  {
    key: 'updatedAt',
    name: 'Updated At'
  },
  {
    key: 'isActive',
    name: 'Status',
    componentType: 'badge'
  },
  {
    key: '',
    name: 'Actions',
    componentType: 'actions'
  }
];
