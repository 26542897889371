import fp from 'lodash/fp';
import api from './api';

import { camelizeKeys } from './helpers';

const composeLoginPayload = payload => ({
  email: fp.get('email')(payload),
  password: fp.get('password')(payload)
});

const adminApi = api('/admin');
const loginApi = api('/login');
const forgotPasswordApi = api('/forgot-password');
const resetPasswordApi = api('/reset-password');

export const fetchUsers = async (data = {}) => {
  const users = await adminApi.get('/users', { params: data });

  return fp.flow(fp.getOr({}, 'data'))(users);
};

export const fetchUser = async (id) => {
  const userDetails = await adminApi.get(`/users/${id}`);
  return fp.flow(
    fp.getOr({}, 'data.data'),
    camelizeKeys
  )(userDetails);
};

export const postLogin = async (payload) => {
  try {
    const loginPayload = composeLoginPayload(payload);
    const loginResp = await loginApi.post('', loginPayload);
    return camelizeKeys(loginResp);
  } catch (error) {
    throw error;
  }
};

export const createUser = async (payload) => {
  try {
    const apiResp = await adminApi.post('/users/create', payload);
    return fp.flow(
      fp.getOr({}, 'data.data'),
      camelizeKeys
    )(apiResp);
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (payload, id) => {
  try {
    const apiResp = await adminApi.put(`/users/${id}`, payload);

    return fp.flow(
      fp.getOr({}, 'data.data'),
      camelizeKeys
    )(apiResp);
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    const apiResp = await adminApi.delete(`/users/${id}`);

    return fp.flow(
      fp.getOr({}, 'data.data'),
      camelizeKeys
    )(apiResp);
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (payload) => {
  try {
    const user = await forgotPasswordApi.post('', payload);
    return fp.getOr({}, 'data.data', user);
  } catch (error) {
    throw error;
  }
};

export const verifyVerificationCode = async (params = {}) => {
  try {
    const response = await resetPasswordApi.get('/verify', { params });
    return fp.getOr({}, 'data', response);
  } catch (error) {
    throw error;
  }
};

export const updateUserPassword = async (payload) => {
  try {
    const userResp = await resetPasswordApi.put('', payload);

    return fp.getOr({}, 'data.data', userResp);
  } catch (error) {
    throw error;
  }
};
