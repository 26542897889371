import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody
} from 'reactstrap';
import fp from 'lodash/fp';
import { connect } from 'react-redux';
import { lifecycle, defaultProps } from 'recompose';
import store from '../../../redux/store';

const UserDetails = ({ userdetail }) => (
  <Card className="card--not-full-height">
    <CardBody>
      <table className="project-summary__info">
        <tbody>
          <tr>
            <th>First Name:</th>
            <td>{fp.get('firstName', userdetail) || 'loading...'}</td>
          </tr>
          <tr>
            <th>Last Name:</th>
            <td>{fp.get('lastName', userdetail) || 'loading...'}</td>
          </tr>
          <tr>
            <th>E-mail:</th>
            <td>{fp.get('email', userdetail) || 'loading...'}</td>
          </tr>
          <tr>
            <th>Status:</th>
            <td>
              {fp.get('isActive', userdetail) ? 'Active' : 'Inactive'}
            </td>
          </tr>
          <tr>
            <th>Created At:</th>
            <td>{fp.get('createdAt', userdetail) || 'loading...'}</td>
          </tr>
        </tbody>
      </table>
    </CardBody>
  </Card>

);

UserDetails.propTypes = {
  userdetail: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    userType: PropTypes.string,
    isActive: PropTypes.bool,
    createdAt: PropTypes.object
  }).isRequired
};

const mapDispatchToProps = dispatch => ({
  fetchUser: dispatch.user.fetchUser
});

const mapStateToProps = (state, ownProps) => {
  const id = fp.get('match.params.id', ownProps);
  return {
    userdetail: store.select.user.getUser(id)(state),
    id
  };
};

export default fp.flow(
  lifecycle({
    componentDidMount() {
      const id = fp.get('id')(this.props);
      this.props.fetchUser(id);
    }
  }),
  defaultProps({
    fetchUser: () => {}
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserDetails);
