import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import PropTypes from 'prop-types';
import SubmitButton from './SubmitButton';

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      showPassword: false
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">E-mail Address</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field name="email" component="input" type="text" placeholder="Name" />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={this.state.showPassword ? 'text' : 'password'}
              placeholder="Password"
            />
            <button
              type="button"
              className={`form__form-group-button${
                this.state.showPassword ? ' active' : ''
              }`}
              onClick={this.showPassword}
            >
              <EyeIcon />
            </button>
          </div>
          {/* <div className="account__forgot-password">
            <a href="/">Forgot a password?</a>
          </div> */}
        </div>
        <SubmitButton />
        {/* <button
              className="btn btn-primary account__btn account__btn--small"
              type="submit">
                Sign In
            </button> */}
        {/* <Link
              className="btn
              btn-outline-primary account__btn account__btn--small"
              to="/log_in">
                Create Account
            </Link> */}
      </form>
    );
  }
}

export default reduxForm({
  form: 'log_in_form'
})(LogInForm);
