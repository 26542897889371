import React from 'react';
import PropTypes from 'prop-types';
import fp from 'lodash/fp';
import { connect } from 'react-redux';
import {
  FormGroup,
  Label,
  FormField
} from '../../../../shared/components/Form';
import KSelect from '../../../../shared/components/KSelect';
import store from '../../../../redux/store';
import { getSelectedLength, getLoadingMessage } from '../../../../redux/models/helpers';

const Agent = ({
  agentHasFetched,
  agentDropdownValues,
  isAgentDropdownValuesLoading,
  fetchAgentDropdownValues,
  onFilter,
  resetAgentDropDownValues
}) => {
  const handleAgentOnChange = (value) => {
    const selectedAgentLength = getSelectedLength(value);

    // fetch Agent when no selected process value
    if (selectedAgentLength === 0) {
      fetchAgentDropdownValues(value);
    }

    // update call recordings result
    onFilter(value);
  };

  const handleAgentBlur = (event) => {
    event.preventDefault();

    if (!fp.isEmpty(event.target.value)) {
      resetAgentDropDownValues();
    }
  };

  return (
    <FormGroup>
      <Label>Operator</Label>
      <FormField
        className="form__form-group-select"
        component={KSelect}
        isLoading={isAgentDropdownValuesLoading}
        isMulti
        name="agent_id[in]"
        noResultsText={
                getLoadingMessage(agentHasFetched, agentDropdownValues)}
        options={agentDropdownValues}
        onBlur={handleAgentBlur}
        onChange={value => handleAgentOnChange(value)}
        onSearch={value =>
                fetchAgentDropdownValues({ value, type: 'ilike' })}
        onFocus={fetchAgentDropdownValues}
        placeHolder="Select Operator..."
      />
    </FormGroup>
  );
};

const optionPropType = PropTypes.shape({
  value: PropTypes.number,
  label: PropTypes.string
});

Agent.propTypes = {
  agentHasFetched: PropTypes.bool.isRequired,
  agentDropdownValues: PropTypes.arrayOf(optionPropType),
  isAgentDropdownValuesLoading: PropTypes.bool.isRequired,
  fetchAgentDropdownValues: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  resetAgentDropDownValues: PropTypes.func.isRequired
};

Agent.defaultProps = {
  agentDropdownValues: []
};

const mapDispatchToProps = dispatch => ({
  fetchAgentDropdownValues: dispatch.filterPanel.fetchAgentDropdownValues,
  resetAgentDropDownValues: dispatch.filterPanel.resetAgent
});

const mapStateToProps = state => ({
  agentHasFetched: state.filterPanel.hasFetched.agents,
  agentDropdownValues: store.select.filterPanel.allAgents(state),
  isAgentDropdownValuesLoading: state.loading.effects.filterPanel
    .fetchAgentDropdownValues
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Agent);
