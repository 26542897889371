import fp from 'lodash/fp';
import api from './api';

const callRecordingTagEndPoint = api('/call-recording-tags');

export const fetchCallRecordingTags = async (queryParams = {}) => {
  const tags = await callRecordingTagEndPoint.get('', { params: queryParams });

  return fp.getOr({}, 'data.data')(tags);
};
