import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import store from '../../../redux/store';
import { isSuperAdmin } from '../../../utils/auth';

class SidebarContent extends Component {
  static propTypes = {
    // changeToDark: PropTypes.func.isRequired,
    // changeToLight: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({}).isRequired,
    onClick: PropTypes.func.isRequired
  };

  hideSidebar = () => {
    this.props.onClick();
  };

  renderCallRecordingTracker() {
    return isSuperAdmin(this.props.currentUser) ? (
      <ul className="sidebar__block">
        <SidebarLink
          title="Call Recording Tracker"
          icon="magnifier"
          route="/admin/call-recording-tracker"
        />
      </ul>) : null;
  }

  render() {
    return (
      <div className="sidebar__content">
        {/* <ul className="sidebar__block">
    <SidebarLink
      title="Log out"
      icon="exit"
      route="/log_in"
      onClick={this.hideSidebar} />
    <SidebarCategory title="Layout" icon="layers">
      <button className="sidebar__link" onClick={this.props.changeToLight}>
        <p className="sidebar__link-title">Light Theme</p>
      </button>
      <button className="sidebar__link" onClick={this.props.changeToDark}>
        <p className="sidebar__link-title">Dark Theme</p>
      </button>
    </SidebarCategory>
  </ul> */}
        <ul className="sidebar__block">
          <SidebarLink title="Dashboard" icon="graduation-hat" route="/admin" />
        </ul>
        {this.renderCallRecordingTracker()}
        <ul className="sidebar__block">
          <SidebarCategory title="User Management" icon="users">
            <SidebarLink title="Users" route="/admin/user-management" />
            <SidebarLink title="Add User" route="/admin/user-management/new" />
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Access Management" icon="lock" route="/admin" />
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="Access Management" icon="users">
            <SidebarLink title="Manage Access" route="/admin/user-management" />
            <SidebarLink title="Add User" route="/admin/user-management/new" />
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="Filter Management" icon="users">
            <SidebarLink title="Data Filters" route="/admin/filter-management" />
            <SidebarLink title="Add Data Filter" route="/admin/filter-management/new" />
          </SidebarCategory>
        </ul>
      </div>
    );
  }
}

const mapState = state => ({
  currentUser: store.select.user.currentUser(state)
});

export default connect(mapState)(SidebarContent);
