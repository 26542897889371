import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { KTables } from '../../../shared/components/KTables';
import store from '../../../redux/store';
import columns from './ColumnsDef';

const List = ({
  isLoading,
  callRecordingTrackerValue,
  columnToSort,
  fetchCallRecordingTracker,
  pageNumber,
  rowsPerPageCount,
  sortDirection,
  totalCount
}) => {
  useEffect(() => {
    fetchCallRecordingTracker();
  }, [fetchCallRecordingTracker]);

  return (
    <KTables
      heads={columns}
      rows={callRecordingTrackerValue}
      order={sortDirection}
      orderBy={columnToSort}
      page={pageNumber}
      rowsCount={rowsPerPageCount}
      total={totalCount}
      onLoading={isLoading}
      onSortClick={fetchCallRecordingTracker}
      onChangePage={fetchCallRecordingTracker}
      onRefreshClick={fetchCallRecordingTracker}
      onChangeRowsPerPage={fetchCallRecordingTracker}
    />
  );
};

List.propTypes = {
  callRecordingTrackerValue: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columnToSort: PropTypes.string.isRequired,
  fetchCallRecordingTracker: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageNumber: PropTypes.number.isRequired,
  rowsPerPageCount: PropTypes.number.isRequired,
  sortDirection: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired
};

const mapDispatchToProps = dispatch => ({
  fetchCallRecordingTracker: dispatch.callRecordingTracker.fetchCallRecordingTrackers
});

const mapStateToProps = state => ({
  callRecordingTrackerValue: store.select.callRecordingTracker.allValues({
    sortBy: state.callRecordingTracker.sortBy,
    sortOrder: state.callRecordingTracker.sortOrder
  })(state),
  columnToSort: state.callRecordingTracker.sortBy,
  isLoading: state.loading.models.callRecordingTracker,
  pageNumber: state.callRecordingTracker.page,
  rowsPerPageCount: state.callRecordingTracker.pageSize,
  sortDirection: state.callRecordingTracker.sortOrder,
  totalCount: state.callRecordingTracker.total
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
