import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import { List } from '../../../components/CallRecordingTracker';

const CallRecordingTrackerComponent = withRouter(List);

const CallRecordingTrackerList = () => (
  <Container>
    <Row>
      <Col md={12} xs={12} lg={12} xl={12}>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Call Recording Tracker</h3>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row style={{ marginTop: '15px' }}>
      <Col>
        <CallRecordingTrackerComponent />
      </Col>
    </Row>
  </Container>
);

export default CallRecordingTrackerList;
