import fp from 'lodash/fp';
import * as dataFieldMappingService from '../../services/data-fields-mapping';

const defaultState = {
  all: {},
  byProcessId: {}
};

export default {
  state: defaultState,

  reducers: {
    loadDataFields(state, dataFields = []) {
      const byDataMappingId = fp.keyBy('id')(dataFields);
      const keyByProcessId = fp.reduce(
        (dataMappingById, dataMapping) => ({
          ...dataMappingById,
          [dataMapping.processId]: fp.omit(['id', 'processId'])(dataMapping)
        }),
        {}
      );
      const dataFieldsByProcessId = keyByProcessId(dataFields);

      return fp.merge(state, {
        all: byDataMappingId,
        byProcessId: dataFieldsByProcessId
      });
    }
  },

  effects: {
    async fetchDataFields(processId) {
      const dataFields = await dataFieldMappingService.fetchDataFieldMapping({
        processId
      });
      return this.loadDataFields(dataFields);
    }
  },

  selectors: (slice, createSelector) => ({
    byProcessId() {
      return createSelector(
        slice,
        fp.get('byProcessId')
      );
    },
    getByProcessId() {
      return createSelector(
        (state, processId) => fp.flow(
          this.byProcessId,
          fp.get(processId)
        )(state),
        dataFields => dataFields
      );
    }
  })
};
