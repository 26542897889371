import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const GenericErrorPage = ({ title, statusCode }) => (
  <div className="generic_pages">
    <h1>{statusCode}</h1>
    <h2>{title} <b>:(</b></h2>
    <div className="gears">
      <div className="gear one">
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
      </div>
      <div className="gear two">
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
      </div>
      <div className="gear three">
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
      </div>
    </div>
    <div className="back_btn">
      <Link className="btn btn-primary" to="/">
        Go Back
      </Link>
    </div>
  </div>
);

GenericErrorPage.propTypes = {
  title: PropTypes.string.isRequired,
  statusCode: PropTypes.number.isRequired
};

export default GenericErrorPage;
