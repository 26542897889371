import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { isSubmitting, hasSubmitFailed, hasSubmitSucceeded } from 'redux-form';
import Link from 'react-router-dom/Link';

import store from '../../../redux/store';
import ForgotPasswordForm from './Form';
import { BasicNotification } from '../../../shared/components/Notification';
import {
  debouncedShowNotification as showNotification,
  instantiateNotification,
  destroyNotification
} from '../../../shared/components/notification/';

const ForgotPassword = ({
  errorResponse,
  onSubmit,
  submitFailed,
  submitting,
  submitSucceeded
}) => {
  useEffect(() => {
    instantiateNotification();
  }, [instantiateNotification]);

  const notify = useCallback((color, title, message) => {
    showNotification({
      notification: <BasicNotification color={color} title={title} message={message} />,
      position: 'right-up'
    });
  }, [submitSucceeded, submitFailed]);

  if (submitSucceeded) {
    notify(
      'success',
      'Check your email!',
      'We emailed you the instructions to reset your password'
    );

    destroyNotification();
    return <Redirect to="/logout" />;
  }

  if (submitFailed) notify('danger', 'Unsuccessful', errorResponse.message);

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              <span className="account__logo">
                  PROTOCALL
                <span className="account__logo-accent">QA</span>
              </span>
            </h3>
          </div>
          <div className="mb-2 text-center">
            <h5> {submitting ? 'sending verification email...' : 'Enter Your Email'}</h5>
          </div>
          <ForgotPasswordForm onSubmit={onSubmit} />
          <div className="mb-2 text-center">
            <Link to="/">
              <p>Back</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

ForgotPassword.propTypes = {
  errorResponse: PropTypes.shape({ message: PropTypes.string }),
  onSubmit: PropTypes.func,
  submitFailed: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

ForgotPassword.defaultProps = {
  onSubmit: () => {},
  errorResponse: {}
};

const mapDispatchToProps = dispatch => ({
  onSubmit: dispatch.user.forgotPassword
});

const mapStateToProps = state => ({
  errorResponse: store.select.user.error(state),
  submitting: isSubmitting('forgot_password_form')(state),
  submitFailed: hasSubmitFailed('forgot_password_form')(state),
  submitSucceeded: hasSubmitSucceeded('forgot_password_form')(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
