import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class TopbarSidebarButton extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired
  };

  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;

    return (
      <div>
        <button
          className="topbar__button topbar__button--desktop"
          onClick={changeSidebarVisibility}
        />
        <button
          className="topbar__button topbar__button--mobile"
          onClick={changeMobileSidebarVisibility}
        />
      </div>
    );
  }
}

export default TopbarSidebarButton;
