import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { isSubmitting, submit as submitAction } from 'redux-form';

export const LinkToCreateButton = () => (
  <Link className="btn btn-outline-primary" to="/admin/user-management/new">
    Add User
  </Link>
);

export const SubmitButton = ({ submitting, submit }) => (
  <Button
    color="primary"
    style={{ float: 'right' }}
    onClick={() => submit('user_form')}
    disabled={submitting}
  >
    Submit
  </Button>
);

SubmitButton.propTypes = {
  submitting: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired
};

const mapState = state => ({
  submitting: isSubmitting('user_form')(state)
});

const mapDispatch = {
  submit: submitAction
};

export default connect(
  mapState,
  mapDispatch
)(SubmitButton);
