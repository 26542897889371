import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { isSubmitting, hasSubmitFailed } from 'redux-form';
import Link from 'react-router-dom/Link';
import LogInForm from './components/LogInForm';
import { BasicNotification } from '../../shared/components/Notification';
import store from '../../redux/store';
import {
  debouncedShowNotification as showNotification,
  destroyNotification,
  instantiateNotification
} from '../../shared/components/notification/';

const LogIn = ({
  onSubmit,
  isLoggedIn,
  resetRecordings,
  resetFilterFormValues, resetAddedFilter, resetSelectedProcess,
  resetCallRecordingTracker, submitting, submitFailed, error
}) => {
  useEffect(() => {
    instantiateNotification();
    resetAddedFilter();
    resetCallRecordingTracker();
    resetFilterFormValues();
    resetRecordings();
    resetSelectedProcess();
  }, []);

  const notifyLoginError = (color, position = 'center-up') => {
    showNotification({
      notification: (
        <BasicNotification color={color} title="Auth Failed" message={error.message} />
      ),
      position
    });
  };

  return (
    <div className="account">
      {/* TODO:// put the actual error message instead of relying from redux-form */}
      {submitFailed ? notifyLoginError('danger') : <span />}
      <div>
        {isLoggedIn && destroyNotification() && <Redirect to="/dashboard" />}
      </div>
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              <span className="account__logo">
                  PROTOCALL
                <span className="account__logo-accent">QA</span>
              </span>
            </h3>
          </div>
          <div className="mb-2 text-center">
            <h5> {submitting ? 'authenticating...' : 'Enter Your Credentials'}</h5>
          </div>
          <LogInForm onSubmit={onSubmit} />
          <div className="mb-2 text-right">
            <Link to="/forgot-password">
              <p>Forgot Password?</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

LogIn.propTypes = {
  error: PropTypes.shape({ message: PropTypes.string }),
  isLoggedIn: PropTypes.bool,
  onSubmit: PropTypes.func,
  resetRecordings: PropTypes.func.isRequired,
  resetFilterFormValues: PropTypes.func.isRequired,
  resetCallRecordingTracker: PropTypes.func.isRequired,
  resetAddedFilter: PropTypes.func.isRequired,
  resetSelectedProcess: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired
};

LogIn.defaultProps = {
  onSubmit: () => {},
  error: {},
  isLoggedIn: false
};

const mapState = state => ({
  isLoggedIn: store.select.user.isLoggedIn(state),
  error: store.select.user.error(state),
  submitting: isSubmitting('log_in_form')(state),
  submitFailed: hasSubmitFailed('log_in_form')(state)
});

const mapDispatch = dispatch => ({
  onSubmit: dispatch.user.postLogin,
  resetRecordings: dispatch.callRecordings.resetRecordings,
  resetFilterFormValues: dispatch.filterPanel.resetFilterFormValues,
  resetCallRecordingTracker: dispatch.callRecordingTracker.resetCallRecordingTracker,
  resetAddedFilter: dispatch.globalConfig.resetAddedFilter,
  resetSelectedProcess: dispatch.globalConfig.resetSelectedProcess
});

export default connect(mapState, mapDispatch)(LogIn);
