import React, { Component } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from '../../pages/Router';
import store from '../../redux/store';
import ScrollToTop from './ScrollToTop';
import Spinner from '../../shared/components/Spinner';

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false
    };
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loaded: true, loading: false });
    });
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <>
              {!loaded && <Spinner loading={loading} />}
              <div>
                <Router store={store} />
              </div>
            </>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default hot(module)(App);
