import { isValidEmail } from '../../../utils/validation';

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  }

  if (!isValidEmail(values.email)) {
    errors.email = 'Invalid email';
  }

  return errors;
};

export default validate;
