import fp from 'lodash/fp';
import { isValidEmail, containsNumber } from '../../../utils/validation';

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    { fieldName: 'firstName', label: 'First Name' },
    { fieldName: 'lastName', label: 'Last Name' },
    { fieldName: 'email', label: 'Email' }
  ];

  requiredFields.forEach((reqField) => {
    if (!fp.has(reqField.fieldName)(values)) {
      errors[reqField.fieldName] = `${reqField.label} field shouldn't be empty`;
    }
    if (!isValidEmail(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (containsNumber(values.firstName)) {
      errors.first_name = 'Invalid First Name';
    }
    if (containsNumber(values.lastName)) {
      errors.last_name = 'Invalid Last Name';
    }
  });

  return errors;
};

export default validate;
