import React, { useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { Col, Row, Container } from 'reactstrap';
import { connect } from 'react-redux';
import fp from 'lodash/fp';
import { withHandlers } from 'recompose';


import { KTables } from '../../../shared/components/KTables';
import store from '../../../redux/store';
import FilterPanel from './FilterPanel';

import CallRecordingTableToolBar from './tableToolBar';
import { getSelectedProcess } from '../../../redux/models/helpers';


const List = ({
  isLoading,
  columns,
  callRecordings,
  onSimpleTableRowClick,
  fetchCallRecordings,
  sortDirection,
  columnToSort,
  pageNumber,
  rowsPerPageCount,
  totalCount,
  process,
  isFilterApplied,
  isRehydrated
}) => {
  useEffect(() => {
    if (fp.isEmpty(callRecordings) && !isFilterApplied && !isLoading && isRehydrated) {
      fetchCallRecordings();
    }
  }, [isRehydrated, isLoading]);

  const memoizedTableToolbarMsg = useMemo(
    () => (callRecordings.length ? '' : 'none found'),
    [callRecordings.length]
  );

  const customTableToolBar = (
    <CallRecordingTableToolBar
      process={process}
      onLoading={isLoading}
      onRefreshClick={fetchCallRecordings}
      message={memoizedTableToolbarMsg}
    />
  );

  if (!isRehydrated) {
    return <span />;
  }

  return (
    <Container style={{ paddingTop: '0' }}>
      <Row>
        <FilterPanel onFilter={fetchCallRecordings} />
        <Col sm="12">
          <div>
            <KTables
              heads={columns}
              rows={callRecordings}
              order={sortDirection}
              orderBy={columnToSort}
              page={pageNumber}
              tableToolbar={customTableToolBar}
              rowsCount={rowsPerPageCount}
              total={totalCount}
              onLoading={isLoading}
              onRowClick={onSimpleTableRowClick}
              onSortClick={fetchCallRecordings}
              onChangePage={fetchCallRecordings}
              onRefreshClick={fetchCallRecordings}
              onChangeRowsPerPage={fetchCallRecordings}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};


List.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    componentType: PropTypes.string
  })).isRequired,
  isFilterApplied: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  callRecordings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sortDirection: PropTypes.string.isRequired,
  columnToSort: PropTypes.string.isRequired,
  pageNumber: PropTypes.number.isRequired,
  isRehydrated: PropTypes.bool.isRequired,
  rowsPerPageCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onSimpleTableRowClick: PropTypes.func.isRequired,
  fetchCallRecordings: PropTypes.func.isRequired,
  process: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string
  }))
};

List.defaultProps = {
  process: []
};

const mapDispatchToProps = dispatch => ({
  resetCallRecordingColumnHandler: dispatch.globalConfig.resetCallRecordingColumnHandler,
  fetchCallRecordings: dispatch.callRecordings.fetchCallRecordings
});

const mapStateToProps = state =>
  ({
    callRecordings: store.select.callRecordings.allValues({
      sortBy: state.callRecordings.sortBy,
      sortOrder: state.callRecordings.sortOrder,
      viewedCallRecordings: state.globalConfig.viewedCallRecordings
    })(state),
    columns: store.select.globalConfig.getCallRecordingColumnsByProcessId(
      state,
      getSelectedProcess(state)
    ),
    columnToSort: state.callRecordings.sortBy,
    isFilterApplied: state.callRecordings.isFilterApplied,
    isLoading: state.loading.models.callRecordings,
    isRehydrated: state.rehydrate.rehydrated,
    pageNumber: state.callRecordings.page,
    process: store.select.filterPanel.allProcess(state),
    rowsPerPageCount: state.callRecordings.pageSize,
    sortDirection: state.callRecordings.sortOrder,
    totalCount: state.callRecordings.total
  });


export default fp.flow(
  withHandlers({
    onSimpleTableRowClick: props => ({ id }) => props.onRowClick(`/call-recordings/${id}`)
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(List);
