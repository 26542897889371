import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import fp from 'lodash/fp';
import { connect } from 'react-redux';
import {
  FormGroup,
  Label,
  FormField
} from '../../../../shared/components/Form';
import KSelect from '../../../../shared/components/KSelect';
import store from '../../../../redux/store';
import { getSelectedLength, getLoadingMessage } from '../../../../redux/models/helpers';

const Process = ({
  resetSection,
  processDropdownValues,
  processHasFetched,
  isProcessDropdownValuesLoading,
  isDataFieldsMappingLoading,
  fetchProcessDropdownValues,
  fetchDataFields,
  onFilter,
  resetFilterDropDownValues,
  resetAddedFilterColumn,
  resetDataFieldDropDownValues,
  resetSelectedProcess,
  resetProcessDropDownValues,
  storeSelectedProcessValue
}) => {
  const clearDataFieldInputs = () => {
    // static for now we have a maximum of 10 fields
    const dataMappingInputFields = [...Array(10).keys()]
      .map(value => `data${value + 1}[in]`);

    // TODO we need to revisit why clearFields isn't working for redux-form
    dataMappingInputFields.forEach(fieldName => resetSection(fieldName));
  };

  const handleProcessOnChange = async (value) => {
    const selectedProcessLength = getSelectedLength(value);

    // fetch Process when no selected process value
    if (selectedProcessLength === 0) {
      fetchProcessDropdownValues(value);
    }

    resetSection('add_filter');
    resetFilterDropDownValues();
    resetAddedFilterColumn();
    resetDataFieldDropDownValues();
    resetSelectedProcess();
    clearDataFieldInputs();
    fetchDataFields({ value: value.value });

    // store selected process value in global state
    storeSelectedProcessValue(value);

    // update call recordings result
    onFilter(value);
  };

  const handleProcessBlur = (event) => {
    event.preventDefault();

    if (!fp.isEmpty(event.target.value)) {
      resetProcessDropDownValues();
    }
  };

  return (
    <FormGroup>
      <Label>Process</Label>
      <FormField
        className="form__form-group-select"
        component={KSelect}
        isLoading={
                isProcessDropdownValuesLoading ||
                isDataFieldsMappingLoading}
        name="process_id"
        noResultsText={getLoadingMessage(
                processHasFetched,
                processDropdownValues
              )}
        options={processDropdownValues}
        onBlur={handleProcessBlur}
        onChange={value => handleProcessOnChange(value)}
        onSearch={value =>
                fetchProcessDropdownValues({ value, type: 'ilike' })}
        onFocus={value =>
                fp.isEmpty(processDropdownValues) &&
                fetchProcessDropdownValues(value)
              }
        placeHolder="Select Process..."
      />
    </FormGroup>
  );
};

const optionPropType = PropTypes.shape({
  value: PropTypes.number,
  label: PropTypes.string
});

Process.propTypes = {
  processDropdownValues: PropTypes.arrayOf(optionPropType),
  isProcessDropdownValuesLoading: PropTypes.bool.isRequired,
  isDataFieldsMappingLoading: PropTypes.bool.isRequired,
  fetchDataFields: PropTypes.func.isRequired,
  processHasFetched: PropTypes.bool.isRequired,
  onFilter: PropTypes.func.isRequired,
  resetProcessDropDownValues: PropTypes.func.isRequired,
  fetchProcessDropdownValues: PropTypes.func.isRequired,
  resetSection: PropTypes.func.isRequired,
  resetFilterDropDownValues: PropTypes.func.isRequired,
  resetSelectedProcess: PropTypes.func.isRequired,
  resetDataFieldDropDownValues: PropTypes.func.isRequired,
  resetAddedFilterColumn: PropTypes.func.isRequired,
  storeSelectedProcessValue: PropTypes.func.isRequired
};

Process.defaultProps = {
  processDropdownValues: []
};

const mapDispatchToProps = dispatch => ({
  fetchProcessDropdownValues: dispatch.filterPanel.fetchProcessDropdownValues,
  fetchDataFields: dispatch.filterPanel.fetchDataFieldMapping,
  resetProcessDropDownValues: dispatch.filterPanel.resetProcess,
  resetFilterDropDownValues: dispatch.filterPanel.resetDataFields,
  resetDataFieldDropDownValues: dispatch.filterPanel.resetDataFieldValues,
  resetSelectedProcess: dispatch.globalConfig.resetSelectedProcess,
  resetAddedFilterColumn: dispatch.globalConfig.resetAddedFilter,
  storeSelectedProcessValue: dispatch.globalConfig.storeSelectedProcessValue
});

const mapStateToProps = state => ({
  isDataFieldsMappingLoading: state.loading.effects.filterPanel
    .fetchDataFieldMapping,
  isProcessDropdownValuesLoading: state.loading.effects.filterPanel
    .fetchProcessDropdownValues,
  processHasFetched: state.filterPanel.hasFetched.process,
  processDropdownValues: store.select.filterPanel.allProcess(state)
});

export default fp.flow(
  reduxForm({
    form: 'call_recording_filter_form',
    destroyOnUnmount: false
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Process);
