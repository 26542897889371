const controllerStyles = {
  // textAlign: 'center',
  // marginTop: '2em'
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  // border: '1px solid #e7e2e2',
  border: '0',
  height: '50px',
  paddingTop: '0.5rem',
  paddingBottom: '0.8rem'
};

const protoCallChannelLabelStyle = {
  position: 'absolute',
  top: '0em',
  left: '0.5em',
  color: '#4FC3F7'
};

const customerChannelLabelStyle = {
  position: 'absolute',
  top: '10em',
  left: '0.5em',
  color: '#4FC3F7'
};

const progressBarStyle = {
  position: 'absolute',
  top: '17em',
  left: 0,
  bottom: 0,
  right: 0,
  margin: 'auto',
  width: '80%'
};

const playerCardContainerStyle = {
  minHeight: '80rem'
};

const sliderStyle = {
  width: '8.5em',
  position: 'absolute',
  right: '4.2em'
};

const playBtnStyle = {

};


module.exports = {
  controllerStyles,
  protoCallChannelLabelStyle,
  customerChannelLabelStyle,
  progressBarStyle,
  playerCardContainerStyle,
  sliderStyle,
  playBtnStyle
};
