import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import fp from 'lodash/fp';
import { connect } from 'react-redux';
import store from '../../../../redux/store';

const CallRecordingDetails = ({
  callRecording,
  dataFields,
  callRecordingId,
  isCallRecordingLoaded,
  isDataFieldsFetching,
  fetchDataMapping,
  fetchCallRecording,
  setCallRecordingToViewed
}) => {
  useEffect(() => {
    fetchCallRecording(callRecordingId);
  }, []);

  useEffect(() => {
    const processId = fp.get('processId')(callRecording);
    if (isCallRecordingLoaded) {
      setCallRecordingToViewed(callRecordingId);
    }

    if (isCallRecordingLoaded) {
      fetchDataMapping(processId);
    }
  }, [isCallRecordingLoaded]);

  const renderCallRecordingAttribute = attribute =>
    fp.get(attribute)(callRecording) || 'loading...';

  return (
    <Card className="card--not-full-height">
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">
            {renderCallRecordingAttribute('fileName')}
          </h5>
        </div>
        <table className="project-summary__info">
          <tbody>
            <tr>
              <th>Process Name:</th>
              <td>{renderCallRecordingAttribute('processName')}</td>
            </tr>
            <tr>
              <th>Date Recorded:</th>
              <td>{renderCallRecordingAttribute('dateRecorded')}</td>
            </tr>
            <tr>
              <th>Start Time:</th>
              <td>{renderCallRecordingAttribute('startTime')}</td>
            </tr>
            <tr>
              <th>Operator:</th>
              <td>{renderCallRecordingAttribute('agentName')}</td>
            </tr>
            <tr>
              <th>Call Length:</th>
              <td>{renderCallRecordingAttribute('callLengthSeconds')}</td>
            </tr>
          </tbody>
        </table>
        <div className="card__title">
          <h5 className="bold-text">Data Fields:</h5>
        </div>
        {isDataFieldsFetching ? 'Loading...' :
        <table className="project-summary__info">
          <tbody>
            {dataFields.map(data => (
              <tr key={data.key}>
                <th>{data.key}</th>
                <td>{data.value}</td>
              </tr>
            ))}
          </tbody>
        </table>}
      </CardBody>
    </Card>
  );
};

CallRecordingDetails.propTypes = {
  callRecording: PropTypes.shape({
    callLengthSeconds: PropTypes.string,
    agentName: PropTypes.string,
    dateRecorded: PropTypes.string,
    fileName: PropTypes.string
  }),
  dataFields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string
  })),
  callRecordingId: PropTypes.number.isRequired,
  isCallRecordingLoaded: PropTypes.bool,
  isDataFieldsFetching: PropTypes.bool,
  fetchDataMapping: PropTypes.func.isRequired,
  fetchCallRecording: PropTypes.func.isRequired,
  setCallRecordingToViewed: PropTypes.func.isRequired
};

CallRecordingDetails.defaultProps = {
  callRecording: {},
  dataFields: [],
  isCallRecordingLoaded: false,
  isDataFieldsFetching: false
};

const mapDispatchToProps = dispatch => ({
  fetchCallRecording: dispatch.callRecordings.fetchCallRecording,
  fetchDataMapping: dispatch.callRecordings.fetchDataMapping,
  setCallRecordingToViewed: dispatch.globalConfig.handleViewedCallRecording
});

const mapStateToProps = (state, ownProps) => {
  const callRecordingId = fp.get('callRecordingId', ownProps);
  const callRecording = store.select.callRecordings
    .getCallRecording(callRecordingId)(state) || {};

  const isCallRecordingLoaded = !fp.isEmpty(callRecording);

  return {
    callRecording,
    dataFields: store.select.callRecordings.getDataFields({
      dataMapping: state.callRecordings.dataFilter,
      callRecordingId
    })(state),
    isDataFieldsFetching: state.loading.effects.callRecordings.fetchDataMapping,
    isCallRecordingLoaded,
    callRecordingId
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CallRecordingDetails);
