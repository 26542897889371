import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import UserTable from '../../../components/User/List/';
import { LinkToCreateButton } from '../../../components/User/Button';

const UserTableWithRouter = withRouter(UserTable);

const List = () => (
  <Container>
    <Row>
      <Col md={12} xs={12} lg={12} xl={12}>
        <Row>
          <Col sm={11} md={11} xs={11} lg={10} xl={10}>
            <h3 className="page-title">List of Users</h3>
          </Col>
          <Col sm={1} md={1} xs={1} lg={2} xl={2}>
            <div style={{ float: 'right' }}><LinkToCreateButton /></div>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <UserTableWithRouter />
    </Row>
  </Container>
);

export default List;
