import fp from 'lodash/fp';
import api from './api';


import { camelizeKeys } from './helpers';

const agentsApi = api('/agents');
const processApi = api('/process');
const clientsApi = api('/organizations');

export const fetchAgents = async (qp = {}) => {
  const agents = await agentsApi.get('', { params: qp });
  return fp.flow(
    fp.getOr({}, 'data.data'),
    camelizeKeys
  )(agents);
};

export const fetchProcess = async (qp = {}) => {
  const process = await processApi.get('', { params: qp });
  return fp.flow(
    fp.getOr({}, 'data.data'),
    camelizeKeys
  )(process);
};

export const fetchClients = async (qp = {}) => {
  const clients = await clientsApi.get('', { params: qp });

  return fp.flow(
    fp.getOr({}, 'data.data'),
    camelizeKeys
  )(clients);
};
