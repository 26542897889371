import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

const CustomMenu = ({ parentRef, items }) => {
  const [isVisible, setVisibility] = useState(false);
  const [yAxis, setY] = useState(0);
  const [xAxis, setX] = useState(0);

  const showMenu = useCallback((event) => {
    event.preventDefault();

    setVisibility(true);
    setX(event.clientX);
    setY(event.clientY);
  }, [setX, setY]);

  const closeMenu = useCallback(() => {
    setVisibility(false);
  }, [isVisible]);

  useEffect(() => {
    const parent = parentRef.current;
    if (!parent) {
      return;
    }

    parent.addEventListener('contextmenu', showMenu);
    window.addEventListener('click', closeMenu);
  }, [showMenu, closeMenu]);

  const style = {
    top: yAxis,
    left: xAxis
  };

  return isVisible ?
    <>
      <div className="context-menu" style={style}>
        <div className="card-menu">
          <ul>
            {items.map(item => (
              <li key={item.text}>
                <button onClick={item.onClick}>
                  {item.text}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </> : <span />;
};

CustomMenu.defaultProps = {
  items: []
};

CustomMenu.propTypes = {
  parentRef: PropTypes.shape({}).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func
  }))
};

export default CustomMenu;
