/* eslint-disable max-lines */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Button } from 'reactstrap';
import fp from 'lodash/fp';
import { connect } from 'react-redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import WaveSurfer from 'wavesurfer.js';

import PlayIcon from 'mdi-react/PlayCircleOutlineIcon';
import PauseIcon from 'mdi-react/PauseCircleOutlineIcon';
import { DownloadIcon } from 'mdi-react';
import MagnifyPlusIcon from 'mdi-react/MagnifyPlusOutlineIcon';
import MagnifyMinusIcon from 'mdi-react/MagnifyMinusOutlineIcon';

import {
  debouncedShowNotification as showNotification,
  instantiateNotification,
  destroyNotification
} from '../../../../shared/components/notification/';
import { BasicNotification } from '../../../../shared/components/Notification';

import store from '../../../../redux/store';
import WaveSurferConfig from './config';
import Progress from './progressBar';
import ToggleSwitch from '../../../../shared/components/form/ToggleSwitch';
import Slider from '../../../../shared/components/range_slider/Slider';
import { SKIP_TIME } from '../../../../constants/app-config';
import CustomMenu from '../../../../shared/components/CustomMenu';


import {
  controllerStyles,
  customerChannelLabelStyle,
  protoCallChannelLabelStyle,
  progressBarStyle,
  sliderStyle
} from './styles';

class Player extends PureComponent {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    callRecording: PropTypes.shape({}).isRequired,
    actionsByStartTime: PropTypes.shape({}).isRequired,
    fetchActionsForCallRecording: PropTypes.func.isRequired,
    toggleFastMode: PropTypes.func.isRequired,
    fetchCallRecording: PropTypes.func.isRequired,
    downloadRecording: PropTypes.func.isRequired,
    hasFetchedCallRecordingActions: PropTypes.bool.isRequired,
    resetHasFetchedCallRecordingAction: PropTypes.func.isRequired,
    rehydrated: PropTypes.bool.isRequired,
    isFastModeEnabled: PropTypes.bool.isRequired,
    isAgentActionsLoading: PropTypes.bool.isRequired,
    isCallRecordingLoaded: PropTypes.bool.isRequired,
    regionData: PropTypes.arrayOf(PropTypes.shape({})),
    accessToken: PropTypes.string,
    startTime: PropTypes.number
  };

  static defaultProps = {
    regionData: [],
    startTime: 0,
    accessToken: ''
  };

  constructor() {
    super();
    this.state = {
      mode: 'FULL_PLAY',
      loadingProgress: 0,
      isWaveSurferInitialized: false,
      isPlaying: false,
      isRecordingLoaded: false,
      isFastModeInitialized: false,
      zoomValue: 0,
      startTime: 0
    };

    if (fp.isNil(WaveSurfer)) {
      throw new Error('WaveSurfer is undefined!');
    }
    this.skipCursor = 0;
    this.timeAllowance = SKIP_TIME;
  }

  componentDidMount() {
    const { id: callRecordingId } = this.props;
    instantiateNotification();

    if (callRecordingId) {
      this.props.fetchCallRecording(callRecordingId);
    }

    this.handleZoom = fp.debounce(250, this.handleZoom);
  }

  // TODO: might be better to use react hooks here?
  componentDidUpdate(prevProps) {
    const {
      id: callRecordingId,
      isAgentActionsLoading,
      isCallRecordingLoaded,
      hasFetchedCallRecordingActions,
      startTime: currentTime
    } = this.props;

    const { isWaveSurferInitialized } = this.state;

    if (
      isCallRecordingLoaded &&
      !hasFetchedCallRecordingActions &&
      !isAgentActionsLoading
    ) {
      // TODO: also check if API incurred an error if so this shouldn't be re-executed
      this.props.fetchActionsForCallRecording(callRecordingId);
    }

    // initialize wavesurfer
    if (
      !isWaveSurferInitialized &&
      isCallRecordingLoaded &&
      hasFetchedCallRecordingActions &&
      !isAgentActionsLoading
    ) {
      this.init();
    }

    if (this.hasAgentActions && isWaveSurferInitialized) {
      this.initializeFastMode();
    }

    if (currentTime !== prevProps.startTime) {
      if (isWaveSurferInitialized) {
        this.waveSurfer.seekTo(0);
        this.waveSurfer.skipForward(currentTime);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    if (this.state.isPlaying) {
      this.waveSurfer.pause();
    }

    if (this.state.isRecordingLoaded) {
      this.waveSurfer.cancelAjax();
      this.waveSurfer.unAll();
    }

    if (this.waveSurfer) {
      this.waveSurfer.unAll();
      this.waveSurfer = null;
    }

    this.props.resetHasFetchedCallRecordingAction();
  }


  onAudioProcess = (progressInSeconds) => {
    const fastModeData = fp.get('fastModeData', this.props);

    const decimal = (progressInSeconds % 1).toFixed(2) * 10;
    const doCheckCursor = decimal % 9 === 0; // do check cursor position

    if (doCheckCursor) {
      const cursorIndex = fp.findIndex(([start, end]) =>
        fp.inRange(start, end)(progressInSeconds))(fastModeData);

      this.skipCursor = cursorIndex > -1 ? cursorIndex : this.skipCursor;
    }

    if (this.skipCursor >= fp.get('length', fastModeData)) {
      this.skipCursor = 0;
    }

    const startTime = fp.get(`${this.skipCursor}.0`, fastModeData) + this.timeAllowance;
    const endTime = fp.get(`${this.skipCursor}.1`, fastModeData);
    const length = endTime - startTime;
    const parsedProgressInSeconds = parseFloat(progressInSeconds.toFixed(1));

    if (
      parsedProgressInSeconds >= startTime &&
      parsedProgressInSeconds <= startTime + 1
    ) {
      if (this.props.isFastModeEnabled) {
        this.waveSurfer.skipForward(parseFloat(length));
      }
      this.incrementSkipCursor();
    }
  };

  get RecordingUrl() {
    const { id } = this.props;
    return `${process.env.REACT_APP_API_URL}/call-recordings/${id}/download`;
  }

  get hasAgentActions() {
    return !fp.isEmpty(this.props.actionsByStartTime);
  }

  getRenderOpacityForWaveform = () => {
    if (this.state.isRecordingLoaded) {
      return 1;
    }

    return (this.state.loadingProgress / 100) - 0.6;
  };

  notifyCopy = (color, URL) => showNotification({
    notification: (
      <BasicNotification
        color={color}
        title="Copied!"
        message={`Successfuly copied ${URL}`}
      />
    ),
    position: 'right-up'
  }) && destroyNotification();

  incrementSkipCursor() {
    this.skipCursor += 1;
  }

  initializeFastMode() {
    if (this.state.isFastModeInitialized) return;

    const fastModeData = fp.get('fastModeData', this.props);

    if (
      this.props.hasFetchedCallRecordingActions &&
      !fp.isEmpty(fastModeData)
    ) {
      this.waveSurfer.on('audioprocess', this.onAudioProcess);
      this.setState({ ...this.state, isFastModeInitialized: true });
    }
  }

  init() {
    this.waveSurfer = WaveSurfer.create(WaveSurferConfig(
      this.props.regionData,
      this.props.accessToken
    ));
    this.setState({
      ...this.state,
      isWaveSurferInitialized: true
    });

    this.waveSurfer.load(this.RecordingUrl, null, 'auto');
    document.addEventListener('keydown', this.handleKeyDown);

    this.waveSurfer.on('ready', () => {
      this.setState({
        loadingProgress: 100,
        isPlaying: this.waveSurfer.isPlaying()
      });

      setTimeout(() => {
        this.setState({ isRecordingLoaded: true });
        const waveSurferBackendState = fp.get('backend.ac.state', this.waveSurfer);

        if (
          waveSurferBackendState !== 'suspended' &&
          !fp.isNil(this.waveSurfer)
        ) {
          this.waveSurfer.play();
        }

        if (!fp.isNil(this.waveSurfer) && this.props.startTime > 0) {
          this.waveSurfer.skipForward(this.props.startTime);
        }
      }, 800);
    });

    this.waveSurfer.on('play', () => {
      this.setState({
        ...this.state,
        isPlaying: true
      });
    });

    this.waveSurfer.on('pause', () => {
      this.setState({
        ...this.state,
        isPlaying: false
      });
    });

    this.waveSurfer.on('loading', (progress) => {
      this.setState({
        ...this.state,
        loadingProgress: progress
      });
    });
  }

  zoomIn = () => {
    const zoomIn = this.state.zoomValue + 5;
    this.setState({
      zoomValue: zoomIn > 100 ? 100 : zoomIn
    });

    this.waveSurfer.zoom(this.state.zoomValue);
  };

  zoomOut = () => {
    const zoomOut = this.state.zoomValue - 5;
    this.setState({
      zoomValue: zoomOut < 0 ? 0 : zoomOut
    });

    this.waveSurfer.zoom(this.state.zoomValue);
  };

  handleKeyDown = (event) => {
    const eventMap = {
      Space: this.togglePlayPause,
      Equal: this.zoomIn,
      Minus: this.zoomOut
    };
    const keyEvent = eventMap[event.code] || null;
    if (!fp.isNull(keyEvent)) {
      keyEvent();
    }
  };

  togglePlayPause = () => {
    if (this.waveSurfer) {
      this.waveSurfer.playPause();
    }
  };

  handleZoom = (zoomValue) => {
    if (!fp.isNil(this.waveSurfer)) {
      this.setState({
        zoomValue
      });

      this.waveSurfer.zoom(zoomValue);
    }
  };

  menuItems = [
    {
      text: 'Copy Call Recording Url',
      onClick: () => {
        const { origin, pathname } = window.location;
        const URL = `${origin}${pathname}`;

        // if navogator is not supported, it will fallback here
        if (fp.isNil(navigator.clipboard)) {
          const dummy = document.createElement('textarea');
          document.body.appendChild(dummy);
          dummy.value = URL;
          dummy.select();
          document.execCommand('copy');
          document.body.removeChild(dummy);
        } else {
          navigator.clipboard.writeText(URL);
        }

        this.notifyCopy('success', URL);
      }
    },
    {
      text: 'Copy Call Recording URL at Current Time',
      onClick: () => {
        const { origin, pathname } = window.location;
        const currentTime = this.waveSurfer.getCurrentTime();
        const URL = `${origin + pathname}?time=${currentTime.toFixed(3)}`;

        // if navogator is not supported, it will fallback here
        if (fp.isNil(navigator.clipboard)) {
          const dummy = document.createElement('textarea');
          document.body.appendChild(dummy);
          dummy.value = URL;
          dummy.select();
          document.execCommand('copy');
          document.body.removeChild(dummy);
        } else {
          navigator.clipboard.writeText(URL);
        }

        this.notifyCopy('success', URL);
      }
    }
  ];

  renderPlayerLabels = (callRecordingId) => {
    if (callRecordingId === 1) {
      return (
        <>
          <h6 style={protoCallChannelLabelStyle} className="bold-text">
            ProtoCall
          </h6>
          <h6 style={customerChannelLabelStyle} className="bold-text">
            Customer
          </h6>
        </>
      );
    }

    return (
      <>
        <h6 style={customerChannelLabelStyle} className="bold-text">
          Customer
        </h6>
        <h6 style={protoCallChannelLabelStyle} className="bold-text">
          ProtoCall
        </h6>
      </>
    );
  };

  render() {
    const { isPlaying, loadingProgress, zoomValue } = this.state;

    return (
      <Card className="card--not-full-height">
        <CardBody>
          <div className="card__title" style={{ marginBottom: '4.5em' }}>
            { !this.props.rehydrated ? '' : (
              <>
                <h5 className="bold-text">Player </h5>
                <ToggleSwitch
                  onChange={this.props.toggleFastMode}
                  label="Fast Mode"
                  defaultChecked={this.props.isFastModeEnabled}
                />
              </>
              )
            }

          </div>
          {/* TODO: move this component to it's own file */}
          <div
            style={{
              minHeight: '1.5em'
            }}
          >
            <div className="slider" style={sliderStyle}>
              <div
                className="slider__min"
                style={{
                  left: '-3em',
                  top: '-0.5em'
                }}
              >
                <MagnifyMinusIcon horizontal />
              </div>
              <Slider
                value={zoomValue}
                min={0}
                max={100}
                index={0}
                onChange={this.handleZoom}
                keyPress={this.handleKeyDown}
              />
              <div className="slider__max" style={{ left: '9.5em', top: '-0.5em' }}>
                <MagnifyPlusIcon horizontal />
              </div>
            </div>
          </div>
          <CustomMenu menuList={this.menuItems}>
            <div
              id="waveform"
              style={{
                position: 'relative',
                minHeight: '250px',
                opacity: this.getRenderOpacityForWaveform()
              }}
            >
              {this.state.isRecordingLoaded && (
                <ReactCSSTransitionGroup
                  transitionName="transition"
                  transitionAppear
                  transitionAppearTimeout={500}
                >
                  <h6 style={protoCallChannelLabelStyle} className="bold-text">
                    {this.props.id === 2 ? 'ProtoCall' : 'Customer'}
                  </h6>
                  <h6 style={customerChannelLabelStyle} className="bold-text">
                    {this.props.id === 2 ? 'Customer' : 'ProtoCall'}
                  </h6>
                </ReactCSSTransitionGroup>
              )}
            </div>
          </CustomMenu>
          {!this.state.isRecordingLoaded && (
            <Progress style={progressBarStyle} value={loadingProgress} />
          )}
          <div id="wave-timeline" />
          <div id="wave-minimap" />

          <div style={controllerStyles}>
            {/* <Button outline>test</Button> */}
            <button className="playBtn" onClick={this.togglePlayPause}>
              {isPlaying ? (
                <PauseIcon
                  size="30"
                  style={{ height: '100%', width: 'auto', margin: '0 auto' }}
                />
              ) : (
                <PlayIcon
                  size="30"
                  style={{ height: '100%', width: 'auto', margin: '0 auto' }}
                />
              )}
            </button>

            <Button
              style={{
              margin: 'auto auto',
              position: 'absolute',
              right: '1.2rem'
              }}
              outline
              size="sm"
              color="primary"

              onClick={(e) => {
                e.preventDefault();
                this.props.downloadRecording(this.props.callRecording);
              }}
            >
              <DownloadIcon style={{ margin: 'auto auto' }} /> Download
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  downloadRecording: dispatch.callRecordings.downloadRecording,
  fetchCallRecording: dispatch.callRecordings.fetchCallRecording,
  fetchActionsForCallRecording:
    dispatch.callRecordingActions.fetchActionsForCallRecording,
  resetHasFetchedCallRecordingAction:
    dispatch.callRecordingActions.resetHasFetchedCallRecordingAction,
  toggleFastMode: dispatch.globalConfig.toggleFastMode
});

const mapStateToProps = (state, ownProps) => {
  const id = fp.get('callRecordingId', ownProps);
  const startTime = fp.get('startTime', ownProps);
  const isFastModeEnabled = store.select.globalConfig.isFastModeEnabled(state);
  const callRecording = store.select.callRecordings.getCallRecording(id)(state) || {};
  const fastModeData =
    store.select.callRecordingActions.getFastModeDataForCrId(id)(state) || [];
  const regionData =
    store.select.callRecordingActions.getRegionDataForCrId(id)(state) || [];
  const currentUser = store.select.user.currentUser(state);
  const hasFetchedCallRecordingActions =
    store.select.callRecordingActions.hasFetchedCallRecordingActions(state);

  return {
    id,
    startTime,
    rehydrated: state.rehydrate.rehydrated,
    callRecording,
    fastModeData,
    regionData,
    isFastModeEnabled,
    isCallRecordingLoaded: !fp.isEmpty(callRecording),
    actionsByStartTime: store
      .select
      .callRecordingActions
      .getAgentActionsForCrId(id)(state) || {},
    isAgentActionsLoading: state.loading.models.callRecordingActions,
    accessToken: fp.getOr(null, 'accessToken', currentUser),
    hasFetchedCallRecordingActions
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Player);
