import React from 'react';
import * as PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import UserManagementList from './List';
import UserCreate from './Create';
import UserDetailsShowPage from './Show';
import UserEditPage from './Edit';

const AdminUserManagementRoute = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/list`} component={UserManagementList} />
    <Route exact path={`${match.path}/new`} component={UserCreate} />
    <Route exact path={`${match.path}`} component={UserManagementList} />
    <Route exact path={`${match.path}/view/:id`} component={UserDetailsShowPage} />
    <Route exact path={`${match.path}/edit/:id`} component={UserEditPage} />
  </Switch>
);

AdminUserManagementRoute.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired
};

export default AdminUserManagementRoute;
