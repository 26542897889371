import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import RefreshIcon from 'mdi-react/RefreshIcon';

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  spacer: {
    flex: '1 1 100%',
    paddingRight: '0',
    paddingLeft: '15px'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  label: {
    whiteSpace: 'nowrap',
    paddingLeft: '25px'
  },
  title: {
    whiteSpace: 'nowrap'
  }
});

const TableToolbar = (props) => {
  const {
    classes,
    children,
    isLoading,
    message,
    onRefreshClick
  } = props;

  return (
    <Toolbar className={classes.spacer}>
      <h5 className={`bold-text ${classes.title}`}>
        Recordings
      </h5>
      <small className={classes.label}>
        {isLoading ? 'loading...' : message}
      </small>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {isLoading ?
          <CircularProgress size="1.5rem" /> :
          <Tooltip title="Reload Data">
            <IconButton onClick={onRefreshClick}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>}
      </div>
      {children}
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.element,
  isLoading: PropTypes.bool.isRequired,
  message: PropTypes.string,
  onRefreshClick: PropTypes.func
};

TableToolbar.defaultProps = {
  children: null,
  message: '',
  onRefreshClick: () => {}
};

export default withStyles(toolbarStyles)(TableToolbar);

