import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import CallRecordingTable from '../../components/CallRecordings/List';

const CallRecordingList = props => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Call Recordings</h3>
      </Col>
    </Row>
    <Row>
      <CallRecordingTable onRowClick={props.history.push} />
    </Row>
  </Container>
);

CallRecordingList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(CallRecordingList);
