import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import { withStyles } from '@material-ui/core/styles';

const toolbarStyles = () => ({
  root: {
    width: '100%'
  },
  arrow: {
    float: 'right'
  },
  refresher: {
    flex: '1 1 100%'
  },
  title: {
    whiteSpace: 'nowrap',
    float: 'left',
    padding: '15px'
  },
  status: {
    paddingLeft: '25px',
    whiteSpace: 'nowrap',
    color: '#646777',
    float: 'left',
    position: 'absolute',
    left: '6em'
  }
});

const PanelToolbar = ({
  classes, title, icon, status
}) => (
  <>
    <div className={classes.root}>
      <h5 className={`bold-text ${classes.title}`}>
        {title}
      </h5>
      {icon ?
        <Tooltip
          className={classes.arrow}
          title={icon === 'arrow-up' ? 'Close Filter' : 'Open Filter'}
        >
          <IconButton>
            {icon === 'arrow-up' ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </IconButton>
        </Tooltip> : ''}
      <div className={classes.refresher} />
    </div>
    <small className={classes.status}>{status}</small>
  </>
);

PanelToolbar.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  status: PropTypes.string,
  icon: PropTypes.string
};

PanelToolbar.defaultProps = {
  status: '',
  icon: ''
};

export default withStyles(toolbarStyles)(PanelToolbar);
