import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import PanelToolBar from './panel-toolbar';

export default class PanelComponent extends PureComponent {
  static propTypes = {
    autoScrollLength: PropTypes.number,
    color: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element)
    ]).isRequired,
    divider: PropTypes.bool,
    hasArrowIcon: PropTypes.bool,
    isCollapsible: PropTypes.bool,
    isExpanded: PropTypes.bool,
    lg: PropTypes.number,
    md: PropTypes.number,
    onExpandChange: PropTypes.func,
    px: PropTypes.number,
    sm: PropTypes.number,
    status: PropTypes.string,
    title: PropTypes.string,
    toolbar: PropTypes.func,
    xl: PropTypes.number,
    xs: PropTypes.number
  };

  static defaultProps = {
    autoScrollLength: 0,
    color: '',
    divider: false,
    isCollapsible: false,
    isExpanded: false,
    lg: 0,
    md: 0,
    onExpandChange: () => {},
    px: 0,
    status: '',
    sm: 0,
    toolbar: () => {},
    title: '',
    hasArrowIcon: false,
    xl: 0,
    xs: 0
  };

  constructor(props) {
    super();

    this.state = {
      visible: true,
      collapse: props.isExpanded,
      refresh: false
    };
  }

  onShow = () => {
    this.setState({ visible: true });
  };

  onDismiss = () => {
    this.setState({ visible: false });
  };

  onCollapse = () => {
    const isCollapse = this.state.collapse;
    const { autoScrollLength, onExpandChange } = this.props;
    if (isCollapse && autoScrollLength > 0) {
      window.scrollTo(0, autoScrollLength);
    }

    onExpandChange(!isCollapse);
    this.setState({ collapse: !isCollapse });
  };

  onRefresh = () => {
    // your async logic here
    this.setState({ refresh: !this.state.refresh });
    setTimeout(() => this.setState({ refresh: false }), 5000);
  };

  arrowAction = () => (this.state.collapse ? 'arrow-up' : 'arrow-down');

  render() {
    const {
      md,
      lg,
      xl,
      sm,
      xs,
      px,
      color,
      divider,
      title,
      hasArrowIcon,
      status,
      toolbar,
      isCollapsible
    } = this.props;

    if (this.state.visible) {
      return (
        <Col md={md} lg={lg} xl={xl} sm={sm} xs={xs}>
          <Card
            className={`panel${this.props.color ? ` panel--${color}` : ''}
          ${divider ? ' panel--divider' : ''}${
              this.state.collapse ? '' : ' panel--collapse'
              }`}
            style={{ paddingBottom: '5px' }}
          >
            <CardBody className="panel__body p-0">
              <Toolbar
                className="panel--toolbar"
                onClick={isCollapsible ? this.onCollapse : ''}
              >
                {toolbar(this.state.collapse) || <PanelToolBar
                  title={title}
                  icon={hasArrowIcon ? this.arrowAction() : ''}
                  status={status}
                />}
              </Toolbar>
              <Collapse isOpen={this.state.collapse}>
                <div className={`panel__content px-${px}`}>
                  {this.props.children}
                </div>
              </Collapse>
            </CardBody>
          </Card>
        </Col>
      );
    }
    return '';
  }
}
