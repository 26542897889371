import React from 'react';
import * as PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import IndexRoute from './List';

const DashboardRoute = ({ match }) => (
  <Switch>
    <Route path={`${match.path}`} component={IndexRoute} />
  </Switch>
);

DashboardRoute.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired
};

export default DashboardRoute;
