import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router';

import UserForm from '../../../components/User/Form';
import SubmitButton from '../../../components/User/Form/SubmitButton';

const WithRouterUserForm = withRouter(UserForm);

const PagesUserCreate = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12} xs={12} lg={12} xl={12}>
        <Row>
          <Col sm={6} md={6} xs={6} lg={6} xl={6}>
            <h3 className="page-title">Add User</h3>
          </Col>
          <Col sm={12} md={6} xs={6} lg={6} xl={6}>
            <SubmitButton />
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <WithRouterUserForm />
    </Row>
  </Container>
);

export default PagesUserCreate;
