/* eslint-disable max-lines */
import fp from 'lodash/fp';
import { getFormValues } from 'redux-form';
import {
  transformSelectOptions,
  normalizeCallRecordingFilters,
  normalizeMultiSelectOptionValues,
  populateDataField
} from './helpers';
import * as filterPanelService from '../../services/filter-panel';
import * as dataFieldMappingService from '../../services/data-fields-mapping';
import * as callRecordingService from '../../services/call-recording';

const defaultState = {
  process: [],
  agents: [],
  dataFields: [],
  dataFieldValues: {},
  clients: [],
  addedFilter: [],
  hasFetched: {
    clients: false,
    agents: false,
    dataFields: false,
    process: false
  }
};

export default {
  state: defaultState,

  reducers: {
    loadProcess(state, response) {
      const options = transformSelectOptions(response);

      return fp.assign(state, {
        process: options,
        hasFetched: { ...state.hasFetched, process: true }
      });
    },

    loadAgents(state, response) {
      const options = transformSelectOptions(response);

      return fp.assign(state, {
        agents: options,
        hasFetched: { ...state.hasFetched, agents: true }
      });
    },

    loadDataMapping(state, response) {
      const dataFields = fp.flow(
        fp.head,
        fp.toPairs,
        fp.map(([key, value]) =>
          (fp.has('key')(value)
            ? {
              value: key,
              label: fp.flow(
                fp.get('displayName'),
                fp.startCase
              )(value)
            }
            : null)),
        fp.reject(fp.isNil)
      )(response);

      return fp.assign(state, { dataFields });
    },

    clearDataMapping(state) {
      return fp.assign(state, {
        dataFields: defaultState.dataFields
      });
    },

    loadClients(state, response) {
      const clients = fp.map(client => ({
        value: client.id,
        label: client.clientKey
      }))(response);

      return fp.assign(state, {
        clients,
        hasFetched: {
          ...state.hasFetched,
          clients: true
        }
      });
    },

    loadDataFieldValues(state, response) {
      const key = fp.get('meta.columnDetails.key')(response);
      const dataFieldValues = {
        [key]: fp.flow(
          fp.sortedUniq,
          fp.reject(fp.isEmpty),
          fp.map(data => ({ value: data, label: data }))
        )(response.data)
      };

      return fp.assign(state, {
        dataFieldValues,
        hasFetched: { ...state.hasFetched, dataFields: true }
      });
    },

    clearAll() {
      return defaultState;
    },

    // TODO: clearDataFields using keys
    clearDataFieldValues(state) {
      return fp.assign(state, {
        dataFieldValues: defaultState.dataFieldValues,
        hasFetched: {
          ...state.hasFetched,
          dataFields: defaultState.hasFetched.dataFields
        }
      });
    },

    clearClient(state) {
      return fp.assign(state, {
        clients: defaultState.clients,
        hasFetched: {
          ...state.hasFetched,
          clients: defaultState.hasFetched.clients
        }
      });
    },

    clearAgent(state) {
      return fp.assign(state, {
        agents: defaultState.agents,
        hasFetched: {
          ...state.hasFetched,
          agents: defaultState.hasFetched.agents
        }
      });
    },

    clearProcess(state) {
      return fp.assign(state, {
        process: defaultState.process,
        hasFetched: {
          ...state.hasFetched,
          process: defaultState.hasFetched.process
        }
      });
    },

    setHasFetched(state, hasFetched = false, type = null) {
      return fp.assign(state, {
        hasFetched: { ...state.hasFetched, [type]: hasFetched }
      });
    }
  },

  effects: {
    async fetchProcessDropdownValues(field, state) {
      this.setHasFetched(false, 'process');
      const filterValues = fp.flow(
        getFormValues('call_recording_filter_form'),
        normalizeCallRecordingFilters
      )(state);

      const clientParams = fp.has('client_key', filterValues) ? {
        client_id: {
          in: normalizeMultiSelectOptionValues(fp.get('client_key.in', filterValues))
        }
      } : {};

      const queryParams = {
        ...clientParams,
        name: fp.isEmpty(field.value) ? {} : { [field.type]: `%${field.value}%` }
      };

      const process = await filterPanelService.fetchProcess(queryParams);
      this.loadProcess(process);
    },

    async fetchAgentDropdownValues(field, state) {
      this.setHasFetched(false, 'agents');
      const filterValues = fp.flow(
        getFormValues('call_recording_filter_form'),
        normalizeCallRecordingFilters
      )(state);

      const clientParams = fp.has('client_key', filterValues) ? {
        client_id: {
          in: normalizeMultiSelectOptionValues(fp.get('client_key.in', filterValues))
        }
      } : {};

      const queryParams = {
        ...clientParams,
        name: fp.isEmpty(field.value) ? {} : { [field.type]: `%${field.value}%` },
        sortBy: 'name',
        sortOrder: 'asc'
      };


      const agents = await filterPanelService.fetchAgents(queryParams);
      this.clearClient();
      this.clearAgent();
      this.loadAgents(agents);
    },

    async fetchClientDropdownValues(field) {
      this.setHasFetched(false, 'clients');
      const queryParams = {
        client_key: fp.isEmpty(field.value) ? {} : { [field.type]: `%${field.value}%` },
        is_client: true
      };

      const dataKeyValue = await filterPanelService.fetchClients(queryParams);

      this.clearClient();
      this.clearAgent();
      this.loadClients(dataKeyValue);
    },

    async fetchDataFieldMapping({ value: processId }) {
      if (fp.isNil(processId)) {
        this.clearDataMapping();
        return;
      }
      const dataFields = await dataFieldMappingService.fetchDataFieldMapping({
        processId
      });
      this.loadDataMapping(dataFields);
    },

    async fetchDataFieldValues(key, state, field) {
      this.setHasFetched(false, 'dataFields');

      const filterValues = fp.flow(
        getFormValues('call_recording_filter_form'),
        (formValues) => {
          const processId = fp.getOr({}, 'process_id', formValues);
          const clientId = fp.getOr({}, 'client_key', formValues);
          const agentId = fp.getOr({}, 'agent_id', formValues);
          const filteredFormValues = fp.omit(['add_filter'], formValues);

          const processField = {
            process_id: processId.value,
            client_id: clientId,
            agent_id: agentId
          };

          return { ...filteredFormValues, ...processField };
        },
        normalizeCallRecordingFilters
      )(state);

      const filteredValues = fp.omit(['client_key'])(filterValues);
      const clientParams = fp.has('client_id.in', filteredValues) ? {
        client_id: {
          in: fp.map('value')(filteredValues.client_id.in)
        }
      } : {};

      const agentParams = fp.has('agent_id', filteredValues) ? {
        agent_id: {
          in: normalizeMultiSelectOptionValues(filteredValues.agent_id.in)
        }
      } : {};

      const queryParams = {
        ...filteredValues,
        process_id: filteredValues.process_id,
        ...clientParams,
        ...agentParams,
        ...populateDataField(filteredValues),
        [key]: fp.isEmpty(field.value) ? {} : { [field.type]: `%${field.value}%` }
      };

      const dataKeyValue = await callRecordingService.fetchDataFieldValues(
        key,
        queryParams
      );

      this.loadDataFieldValues(dataKeyValue);
    },

    resetDataFields() {
      this.clearDataMapping();
    },

    resetFilterFormValues() {
      this.clearAll();
    },

    resetClient() {
      this.clearClient();
    },

    resetAgent() {
      this.clearAgent();
    },

    resetProcess() {
      this.clearProcess();
    },

    // TODO reset data field values given a key.
    resetDataFieldValues() {
      this.clearDataFieldValues();
    }
  },

  selectors: (slice, createSelector) => ({
    allProcess() {
      return createSelector(
        slice,
        fp.get('process')
      );
    },
    allAgents() {
      return createSelector(
        slice,
        fp.get('agents')
      );
    },
    allClients() {
      return createSelector(
        slice,
        fp.get('clients')
      );
    },
    getDataFields() {
      return createSelector(
        slice,
        fp.get('dataFields')
      );
    },
    // TODO: should get dataField values using key
    getDataFieldValues() {
      return createSelector(
        slice,
        fp.get('dataFieldValues')
      );
    },
    getAddedFilters() {
      return createSelector(
        slice,
        fp.get('addedFilter')
      );
    }
  })
};
