import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';
import Menu from './CustomMenu';

const CustomMenu = ({ children, menuList }) => {
  const containerRef = useRef();

  return (
    <div ref={containerRef}>
      {children}
      <Menu parentRef={containerRef} items={menuList} />
    </div>
  );
};

CustomMenu.propTypes = {
  menuList: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func
  })).isRequired,
  children: PropTypes.element.isRequired
};

export default CustomMenu;
