import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { isValid, isPristine, isSubmitting, submit as submitAction } from 'redux-form';

const SubmitButton = ({
  submit, submitting, valid, pristine
}) => (
  <Button
    color="primary"
    style={{ width: '100%' }}
    onClick={() => submit('reset_password_form')}
    disabled={!valid || pristine || submitting}
  >
    Save
  </Button>
);

SubmitButton.propTypes = {
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired
};

const mapState = state => ({
  submitting: isSubmitting('reset_password_form')(state),
  valid: isValid('reset_password_form')(state),
  pristine: isPristine('reset_password_form')(state)
});

const mapDispatch = {
  submit: submitAction
};

export default connect(mapState, mapDispatch)(SubmitButton);
