import fp from 'lodash/fp';

export const isAdmin = fp.flow(
  fp.get('userRoles'),
  fp.some(role => role.level > 1)
);

export const isKombeaAdmin = fp.flow(
  fp.get('userRoles'),
  fp.some(role => role.level === 4)
);

export const isSuperAdmin = fp.flow(
  fp.get('userRoles'),
  fp.some(role => role.level === 5)
);

export const getUserRole = (user = {}, orgId) => {
  const roles = fp.getOr([], 'userRoles')(user);

  if (isSuperAdmin(user)) {
    return fp.flow(
      fp.filter({ level: 5 }),
      fp.head
    )(roles);
  }

  if (isKombeaAdmin(user)) {
    return fp.flow(
      fp.filter({ level: 4 }),
      fp.head
    )(roles);
  }

  const userRole = fp.flow(
    fp.filter({ clientId: orgId }),
    fp.head
  )(roles);

  return !fp.isEmpty(userRole) ? userRole : {};
};

export const getClientKeys = fp.flow(
  fp.getOr([], 'clients'),
  fp.map('clientKey')
);

export const getClientIds = fp.flow(
  fp.getOr([], 'clients'),
  fp.map('clientId')
);
