import fp from 'lodash/fp';
import api from './api';

import { toSnakeCase } from './helpers';

const callRecordingActionsApi = api('/call-recording-actions');

const apiGet = (params = {}) =>
  callRecordingActionsApi.get('', { params: toSnakeCase(params) });

export const fetchCallRecordingActions = async (filters = {}) => {
  const callRecordingActions = await apiGet(filters);
  return fp.flow(fp.getOr({}, 'data.data'))(callRecordingActions);
};
