import React from 'react';
import { Progress } from 'reactstrap';
import PropTypes from 'prop-types';

const ProgressBar = ({ value, ...props }) => (
  <div {...props} className="progress-wrap progress-wrap--middle">
    <Progress animated value={value} />
  </div>
);

ProgressBar.propTypes = {
  value: PropTypes.number
};

ProgressBar.defaultProps = {
  value: 0
};

export default ProgressBar;
