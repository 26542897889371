/* eslint-disable no-return-assign */

import NotificationSystem from 'rc-notification';
import fp from 'lodash/fp';

const AGNotification = {
  notificationLU: null,
  notificationRU: null,
  notificationTC: null
};

const showNotification = ({ notification, position }) => {
  switch (position) {
    case 'left-up':
      AGNotification.notificationLU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 240 },
        className: position
      });
      break;
    case 'right-up':
      AGNotification.notificationRU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        getContainer: () => document.getElementById('root'),
        className: position
      });
      break;
    case 'center-up':
      AGNotification.notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(50vw - 15vw)', width: '35em' },
        getContainer: () => document.getElementById('root'),
        className: position
      });
      break;
    default:
      AGNotification.notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position
      });
      break;
  }
};

export const destroyNotification = () => {
  try {
    AGNotification.notificationLU.destroy();
    AGNotification.notificationRU.destroy();
    AGNotification.notificationTC.destroy();
    return true;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
    return true;
  }
};

export const instantiateNotification = () => {
  NotificationSystem.newInstance({}, n => (AGNotification.notificationLU = n));
  NotificationSystem.newInstance({}, n => (AGNotification.notificationRU = n));
  NotificationSystem.newInstance({}, n => (AGNotification.notificationTC = n));
};

export const debouncedShowNotification = fp.debounce(500, showNotification);
