/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React from 'react';
import * as PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import fp from 'lodash/fp';
import { withHandlers, lifecycle, defaultProps } from 'recompose';

import { KTables } from '../../../shared/components/KTables';
import store from '../../../redux/store';
import columnsDef from './columnsDef';

const Table = ({
  isLoading,
  columns,
  users,
  fetchUsers,
  sortDirection,
  columnToSort,
  pageNumber,
  rowsPerPageCount,
  totalCount,
  onTableViewButtonClick,
  onTableEditButtonClick,
  onTableDeleteButtonClick,
  actionButtons
}) => (
  <Col md={12} lg={12}>
    <div>
      <KTables
        heads={columns}
        rows={users}
        order={sortDirection}
        orderBy={columnToSort}
        page={pageNumber}
        rowsCount={rowsPerPageCount}
        total={totalCount}
        onSortClick={fetchUsers}
        onLoading={isLoading}
        onChangePage={fetchUsers}
        onChangeRowsPerPage={fetchUsers}
        onViewButtonClick={onTableViewButtonClick}
        onEditButtonClick={onTableEditButtonClick}
        onDeleteButtonClick={onTableDeleteButtonClick}
        onRefreshClick={fetchUsers}
        actionButtons={actionButtons}
      />
    </div>
  </Col>
);

Table.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  sortDirection: PropTypes.string.isRequired,
  columnToSort: PropTypes.string.isRequired,
  pageNumber: PropTypes.number.isRequired,
  rowsPerPageCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  onTableViewButtonClick: PropTypes.func,
  onTableEditButtonClick: PropTypes.func,
  onTableDeleteButtonClick: PropTypes.func,
  actionButtons: PropTypes.shape({}).isRequired
};

Table.defaultProps = {
  onTableViewButtonClick: () => {},
  onTableEditButtonClick: () => {},
  onTableDeleteButtonClick: () => {}
};

const mapDispatchToProps = dispatch => ({
  fetchUsers: dispatch.user.fetchUsers,
  onTableDeleteButtonClick: dispatch.user.delete
});

const mapStateToProps = state => ({
  isLoading: state.loading.models.user,
  sortDirection: state.user.sortOrder,
  columnToSort: state.user.sortBy,
  pageNumber: state.user.page,
  rowsPerPageCount: state.user.pageSize,
  totalCount: state.user.total,
  users: store.select.user.allValues({
    sortBy: state.user.sortBy,
    sortOrder: state.user.sortOrder
  })(state),
  columns: columnsDef,
  actionButtons: state.user.actionButtons
});

export default fp.flow(
  lifecycle({
    componentDidMount() {
      this.props.fetchUsers();
    }
  }),
  withHandlers({
    onTableViewButtonClick: props => ({ id }) =>
      props.history.push(`/admin/user-management/view/${id}`),
    onTableEditButtonClick: props => ({ id }) =>
      props.history.push(`/admin/user-management/edit/${id}`)
  }),
  defaultProps({
    fetchUsers: () => {}
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Table);
