import fp from 'lodash/fp';
import { getUserRole } from './auth';
import { userListActions } from '../constants/user';

const validateActionButtons = (currentUser, data = []) => {
  const getActionButtons = fp.reduce((accumulator, user) => {
    const userOrgId = fp.get('organizationId')(user);
    const currentUserOrgId = fp.get('organizationId')(currentUser);

    const currentUserRole = getUserRole(currentUser, userOrgId);
    const userToIterateRole = getUserRole(user, userOrgId);

    const isInTheSameOrg = fp.isEqual(currentUserOrgId, userOrgId);

    if (currentUserRole.level >= 4) {
      return {
        ...accumulator,
        [user.id]: [
          userListActions.VIEW,
          userListActions.EDIT,
          userListActions.DELETE]
      };
    }

    // current logged in is in the list
    if (currentUser.id === user.id) {
      return {
        ...accumulator,
        [user.id]: [
          userListActions.VIEW,
          userListActions.EDIT]
      };
    }

    // if currentUser role level is higher than user to apply actions
    if (isInTheSameOrg && currentUserRole.level > userToIterateRole.level) {
      return {
        ...accumulator,
        [user.id]: [
          userListActions.VIEW,
          userListActions.EDIT,
          userListActions.DELETE]
      };
    }

    return {
      ...accumulator,
      [user.id]: [userListActions.VIEW]
    };
  }, {})(data);

  return getActionButtons;
};

export default validateActionButtons;
