import React, { useState } from 'react';
import fp from 'lodash/fp';
import PropTypes from 'prop-types';

// TODO: create a shared component for switch
import Switch from 'react-switch';
import styled from 'styled-components';

const StyledToggleLabel = styled.span`
  line-height: normal;
  margin-right: 0.5rem
`;

const StyledToggleContainer = styled.div`
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function ToggleSwitch({ onChange, label, defaultChecked }) {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const toggleIsChecked = () => {
    onChange(isChecked);
    setIsChecked(!isChecked);
  };

  return (
    <StyledToggleContainer>
      {!fp.isNil(label) ? <StyledToggleLabel>Fast Mode</StyledToggleLabel> : ''}
      <Switch
        onColor="#86d3ff"
        onHandleColor="#2693e6"
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        uncheckedIcon={false}
        checkedIcon={false}
        handleDiameter={30}
        height={20}
        width={60}
        className="react-switch"
        checked={isChecked}
        onChange={toggleIsChecked}
      />
    </StyledToggleContainer>
  );
}

ToggleSwitch.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  defaultChecked: PropTypes.bool
};

ToggleSwitch.defaultProps = {
  onChange: () => {},
  label: null,
  defaultChecked: true
};

