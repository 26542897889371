import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline';
import RegionPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions';
import MinimapPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.minimap';
import fp from 'lodash/fp';

export default (regionData, accessToken) => {
  const defaultConfig = {
    container: '#waveform',
    waveColor: '#BBDEFB',
    cursorColor: '#e5a30d',
    cursorWidth: '0.5',
    progressColor: '#2196F3',
    // backend: 'MediaElement',
    splitChannels: true,
    // barWidth: 2,
    normalize: true,
    xhr: {
      requestHeaders: [
        {
          key: 'Authorization',
          value: `Bearer ${accessToken}`
        }
      ]
    }
  };
  const plugins = [
    CursorPlugin.create({
      showTime: true,
      container: '#waveform',
      width: '0.5px',
      opacity: 1,
      customShowTimeStyle: {
        'background-color': '#000',
        color: '#fff',
        opacity: 0.8,
        padding: '2px',
        'font-size': '10px'
      }
    }),
    TimelinePlugin.create({
      container: '#wave-timeline'
    }),
    MinimapPlugin.create({
      container: '#wave-minimap',
      waveColor: '#03A9F4',
      progressColor: '#2196F3',
      height: 30,
      showOverview: true,
      overviewBorderColor: '#BBDEFB',
      overviewBorderSize: 0,
      overviewOpacity: 0.1
    })
  ];

  const wsRegions = RegionPlugin.create({
    regions: regionData.map(({
      start, end, value, type
    }) => {
      // design for object literal, if we wan't to add more custom keys
      // we can append in actionKeysMap
      const actionKeysMap = {
        space: '␣',
        mouse: '⬉'
      };

      const actionFilter = key => actionKeysMap[key.toLowerCase()] || value;

      return ({
        start,
        end,
        loop: false,
        resize: false,
        drag: false,
        channelIdx: 1,
        attributes: {
          label: actionFilter(value || type),
          name: 'agentAction',
          actionType: fp.toLower(type)
        }
      });
    })
  });


  return {
    ...defaultConfig,
    plugins: [
      ...plugins,
      wsRegions
    ]
  };
};
