import fp from 'lodash/fp';
import axios from 'axios';
import fileDownload from 'js-file-download';
import api from './api';
import { camelizeKeys } from './helpers';


const callRecordingsApi = api('/call-recordings');
const dataFielsApi = api('/data-fields-mapping');

export const fetchCallRecordings = async (data = {}) => {
  const callRecordings = await callRecordingsApi.get('', { params: data });
  return fp.flow(fp.getOr({}, 'data'))(callRecordings);
};

export const fetchCallRecording = async (id) => {
  const callRecording = await callRecordingsApi.get(`/${id}`);

  const a = fp.flow(
    fp.getOr({}, 'data.data'),
    camelizeKeys
  )(callRecording);

  return a;
};

export const fetchCallRecordingByUUID = async (uuid) => {
  const callRecording = await callRecordingsApi.get(`/uuid/${uuid}`);

  const response = fp.flow(
    fp.getOr({}, 'data.data'),
    camelizeKeys
  )(callRecording);

  return response;
};

export const downloadRecording = async (callRecording) => {
  const { id } = callRecording;
  axios.get(`${process.env.REACT_APP_API_URL}/call-recordings/${id}/download`, {
    responseType: 'blob'
  })
    .then((res) => {
    // TODO: make file type dynamic based on actual file name
      fileDownload(res.data, `${callRecording.fileName}`);
    });
};

export const fetchDataMapping = async (processId) => {
  const dataFieldMappings = await dataFielsApi.get('', { params: { processId } });

  return fp.flow(
    fp.getOr({}, 'data.data'),
    camelizeKeys
  )(dataFieldMappings);
};

export const fetchDataFieldValues = async (key, qp = {}) => {
  const dataFieldValues = await callRecordingsApi.get(`/${key}/values`, {
    params: qp
  });

  return fp.flow(
    fp.getOr({}, 'data'),
    camelizeKeys
  )(dataFieldValues);
};
