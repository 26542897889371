/* eslint-disable max-lines */
import fp from 'lodash/fp';
import moment from 'moment';
import { getFormValues } from 'redux-form';

import {
  structureById,
  transformCallRecording,
  normalizeCallRecordingFilters,
  normalizeMultiSelectOptionValues,
  composeMetaData,
  populateDataField
} from './helpers';
import * as callRecordingService from '../../services/call-recording';
import { isKombeaAdmin, isSuperAdmin } from '../../utils/auth';

const defaultState = {
  all: {},
  dataFilter: [],
  sortBy: 'startTime',
  sortOrder: 'desc',
  page: 0,
  pageSize: 25,
  total: 0,
  isFilterApplied: false
};

export default {
  state: defaultState,

  reducers: {
    getCallRecordings(state, response) {
      const transformedCallRec = response.data.map(transformCallRecording);
      const callRecValues = { all: structureById(transformedCallRec) };
      const callRecordings = fp.merge(callRecValues, response.meta);

      return fp.assign(state, callRecordings);
    },
    insertCallRecording(state, recording) {
      return fp.merge(state, {
        all: {
          [recording.id]: recording
        }
      });
    },
    getDataFilter(state, response) {
      const dataFields = fp.flow(
        fp.head,
        fp.toPairs,
        fp.map(([key, value]) => ({ key, value: fp.get('displayName', value) })),
        fp.reject(obj => fp.isNil(obj.value))
      )(response);

      return fp.assign(state, { dataFilter: dataFields });
    },
    clearAll() {
      return defaultState;
    },

    setFilterApplied(state, isFilterApplied) {
      return fp.merge(state, { isFilterApplied });
    },

    loadColumn(state, column) {
      return fp.merge(state, { column });
    }
  },

  effects: {
    async fetchCallRecordings(payload = {}, state) {
      const shouldInvertSort = !fp.isEmpty(payload) && fp.has('sortBy', payload);
      const metaData = composeMetaData(state.callRecordings, payload, shouldInvertSort);

      const { currentUser } = state.user;

      // clients| allowed call recordings to be viewed
      const clientKeys = fp.flow(
        fp.getOr([], 'user.currentUser.clients'),
        fp.map('clientKey')
      )(state);

      const shouldFilterByClient =
        !isKombeaAdmin(currentUser) &&
        !isSuperAdmin(currentUser) &&
        !fp.isEmpty(clientKeys);

      const filterValues = fp.flow(
        getFormValues('call_recording_filter_form'),
        (formValues) => {
          // TODO: refactor this!
          const dataFieldKeys = [...Array(10).keys()].map(index => `data${index + 1}`);
          const processIdField = fp.get('process_id', formValues);
          const hasProcessId = !fp.isNil(fp.get('process_id', formValues));
          const formDataFields = !hasProcessId
            ? fp.omit(dataFieldKeys, formValues)
            : formValues;
          const processField = hasProcessId ? { process_id: processIdField.value } : {};
          const filteredFormDataFields = fp.omit(['add_filter'], formDataFields);

          return { ...filteredFormDataFields, ...processField };
        },
        normalizeCallRecordingFilters
      )(state);

      /* TODO: rename this to isFilterPresent the purpose of this state is to know
       whether filterValues are present or in filters are applied to the filter panel */
      const isFilterApplied = !fp.isEmpty(filterValues);

      const queryParams = fp.merge(metaData, {
        ...filterValues,
        // if user is a kombea admin, don't filter by client id
        ...(shouldFilterByClient ? {
          client_key: {
            in: clientKeys
          }
        } : {}),
        ...(fp.has('client_key.in', filterValues) ? {
          client_key: {
            in: fp.map('label')(filterValues.client_key.in)
          }
        } : {}),
        ...(fp.has('agent_id', filterValues) ? {
          agent_id: {
            in: normalizeMultiSelectOptionValues(filterValues.agent_id.in)
          }
        } : {}),
        ...populateDataField(filterValues)
      });

      const callRecordings = await callRecordingService.fetchCallRecordings(queryParams);
      this.setFilterApplied(isFilterApplied);
      this.getCallRecordings(callRecordings);
    },

    async fetchCallRecording(id) {
      const callRecording = await callRecordingService.fetchCallRecording(id);
      const transformedCR = transformCallRecording(callRecording);

      this.insertCallRecording(transformedCR);
    },

    async fetchCallRecordingByUUID(uuid) {
      const callRecording = await callRecordingService.fetchCallRecordingByUUID(uuid);
      const transformedCR = transformCallRecording(callRecording);

      this.insertCallRecording(transformedCR);
    },

    async fetchDataMapping(processId) {
      const getDataFilter = await callRecordingService.fetchDataMapping(processId);
      this.getDataFilter(getDataFilter);
    },

    async downloadRecording(id) {
      try {
        await callRecordingService.downloadRecording(id);
        return true;
      } catch (error) {
        throw error;
      }
    },

    resetRecordings() {
      this.clearAll();
    }
  },

  selectors: (slice, createSelector, hasProps) => ({
    all() {
      return createSelector(
        slice,
        fp.get('all')
      );
    },
    allValues: hasProps((models, { sortBy, sortOrder, viewedCallRecordings = [] }) =>
      slice(fp.flow(
        fp.get('all'),
        fp.values,
        fp.orderBy(sortBy, sortOrder),
        callRecordings =>
          callRecordings.map((callRecording) => {
            const callRecordingId = fp.flow(
              fp.get('id'),
              fp.toNumber
            )(callRecording);

            const processName = fp.get('processName', callRecording);
            const clientName = fp.get('clientKey', callRecording);
            const clientWithProcessName = `${clientName} | ${processName}`;

            const startTime = fp.flow(
              fp.get('startTime'),
              formatedDate => moment(formatedDate).format('YYYY-MM-DD')
            )(callRecording);

            if (fp.includes(callRecordingId, viewedCallRecordings)) {
              return {
                ...callRecording,
                isViewed: true,
                clientWithProcessName,
                startTime
              };
            }
            return {
              ...callRecording,
              isViewed: false,
              clientWithProcessName,
              startTime
            };
          })
      ))),
    getCallRecording: hasProps((models, id) =>
      slice(fp.flow(
        fp.get('all'),
        fp.get(id)
      ))),
    getCallRecordingByUUID: hasProps((models, uuid) =>
      slice(fp.flow(
        fp.get('all'),
        fp.values,
        res => console.log('#DEBUG1', res) || res,
        fp.filter(obj => console.log('DEBUG2', { obj, uuid }) || obj.uuid === uuid),
        res => console.log('#DEBUG', res) || res,
        fp.head
      ))),
    getFilters() {
      return createSelector(
        slice,
        fp.get('filters')
      );
    },
    // TODO: double check if this is used anywhere in the codebase
    getFilterValue() {
      return createSelector(
        (_, filterName) => filterName,
        this.getFilters,
        fp.get
      );
    },
    getDataFields: hasProps((models, dataFieldsParams = {}) =>
      slice(fp.flow(
        fp.get('all'),
        fp.get(dataFieldsParams.callRecordingId),
        (callRecording) => {
          const { dataMapping } = dataFieldsParams;

          return fp.flow(
            fp.map((dataMap) => {
              const { key, value: label } = dataMap;
              const value = fp.getOr(null, key, callRecording);

              if (fp.isNil(label)) {
                return null;
              }

              return {
                key: label,
                value
              };
            }),
            fp.reject(fp.isNil)
          )(dataMapping);
        }
      )))
  })
};
