import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import { withRouter } from 'react-router-dom';
import UserDetail from '../../../components/User/Details';

const UserDetailWithRouter = withRouter(UserDetail);

const UserDetails = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">User Details</h3>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <UserDetailWithRouter />
      </Col>
    </Row>
  </Container>
);

export default UserDetails;
