import React, { useEffect, useCallback } from 'react';
import fp from 'lodash/fp';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Link from 'react-router-dom/Link';
import { isSubmitting, hasSubmitFailed, hasSubmitSucceeded } from 'redux-form';

import store from '../../../redux/store';
import ResetPasswordForm from './Form';
import { BasicNotification } from '../../../shared/components/Notification';
import {
  debouncedShowNotification as showNotification,
  instantiateNotification,
  destroyNotification
} from '../../../shared/components/notification/';

const ResetPassword = ({
  errorResponse,
  onFailedValidation,
  onSubmit,
  submitFailed,
  submitSucceeded,
  submitting,
  userVerificationCode,
  verifyVerificationCode
}) => {
  useEffect(() => {
    instantiateNotification();
  }, [instantiateNotification]);

  const notify = useCallback((color, title, message) => {
    showNotification({
      notification: <BasicNotification color={color} title={title} message={message} />,
      position: 'right-up'
    });
  }, [submitSucceeded, submitFailed]);

  const validateVerificationCode = async (verificationCode = '') => {
    if (fp.isEmpty(verificationCode)) {
      notify('danger', 'Sorry!', 'Reset password URL is invalid');
      onFailedValidation();
    }

    const response = await verifyVerificationCode(verificationCode);
    if (!response.isVerified) {
      notify('danger', 'Sorry!', 'Reset password URL is invalid');

      // call onFailedValidation to trigger redirect in dispatch
      onFailedValidation();
    }
  };

  useEffect(() => {
    validateVerificationCode(userVerificationCode);
  }, [userVerificationCode]);

  if (submitSucceeded) {
    notify('success', 'Success!!', 'You can now login using your new password');

    destroyNotification();
    return <Redirect to="/logout" />;
  }

  if (submitFailed) notify('danger', 'Unsuccessful', errorResponse.message);

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              <span className="account__logo">
                  PROTOCALL
                <span className="account__logo-accent">QA</span>
              </span>
            </h3>
          </div>
          <div className="mb-2 text-center">
            <h5> {submitting ? 'Sending...' : 'Enter Your Password'}</h5>
          </div>
          <ResetPasswordForm onSubmit={onSubmit} />
          <div className="mb-2 text-center">
            <Link to="/">
              <p>Login</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  errorResponse: PropTypes.shape({ message: PropTypes.string }),
  onSubmit: PropTypes.func,
  onFailedValidation: PropTypes.func.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  userVerificationCode: PropTypes.string.isRequired,
  verifyVerificationCode: PropTypes.func.isRequired
};

ResetPassword.defaultProps = {
  onSubmit: () => {},
  errorResponse: {}
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const searchParams = fp.get('location.search', ownProps);
  const userVerificationCode = new URLSearchParams(searchParams);
  const { resetPassword, verifyVerificationCode } = dispatch.user;

  return ({
    onSubmit: () => resetPassword(userVerificationCode.get('verification_code')),
    onFailedValidation: () => ownProps.history.push('/logout') && destroyNotification(),
    verifyVerificationCode
  });
};

const mapStateToProps = (state, ownProps) => {
  const searchParams = fp.get('location.search', ownProps);
  const userVerificationCode = new URLSearchParams(searchParams);

  return {
    errorResponse: store.select.user.error(state),
    submitting: isSubmitting('reset_password_form')(state),
    submitFailed: hasSubmitFailed('reset_password_form')(state),
    submitSucceeded: hasSubmitSucceeded('reset_password_form')(state),
    userVerificationCode: userVerificationCode.get('verification_code')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
