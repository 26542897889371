import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Portal } from 'react-overlays';

// TODO: memoized this!
const calendarContainer = ({ children }) => (
  <Portal>
    {children}
  </Portal>
);

calendarContainer.propTypes = {
  children: PropTypes.element
};

calendarContainer.defaultProps = {
  children: null
};

const DatePickerField = ({
  value,
  onChange
}) => {
  const handleChange = (date) => {
    onChange(moment(date).toISOString());
  };

  const selected = value ? moment(value) : null;

  return (
    <div className="date-picker">
      <DatePicker
        className="form__form-group-datepicker"
        dateFormat="LL"
        onChange={handleChange}
        popperContainer={calendarContainer}
        selected={selected}
      />
    </div>
  );
};

DatePickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

DatePickerField.defaultProps = {
  value: null
};

const renderDatePickerField = props => <DatePickerField {...props.input} />;

export default renderDatePickerField;
