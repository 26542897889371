import React, { memo } from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const StyledBadge = styled(Button)`
  margin: 5px;
  padding: 1px 5px !important;
  font-size: 12px;
`;

const Tag = ({ tagName, redirect, ...props }) => (
  <Link to={redirect}>
    <StyledBadge
      outline
      color={props.isActive ? 'primary' : 'secondary'}
      isActive={props.isActive}
      {...props}
    >
      <small>{tagName}</small>
    </StyledBadge>
  </Link>
);


Tag.propTypes = {
  tagName: PropTypes.string.isRequired,
  isActive: PropTypes.bool
};

Tag.defaultProps = {
  isActive: false
};

export default memo(Tag);
