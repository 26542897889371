export default [
  {
    key: 'fileName',
    name: 'File Name'
  },
  {
    key: 'timestamp',
    name: 'Ingested At'
  },
  {
    key: 'agentName',
    name: 'Operator'
  },
  {
    key: 'errorMsg',
    name: 'Error Message'
  },
  {
    key: 'status',
    name: 'Ingestion Status'
  }
];
