import React from 'react';
import PropTypes from 'prop-types';
import fp from 'lodash/fp';
import { Switch, Redirect } from 'react-router-dom';

import Layout from './AdminLayout/index';
import AuthenticatedRoute from '../components/HoC/AuthenticatedRoute/index';
import AdminRoute from './Admin/Route';

import { isAdmin } from '../utils/auth';

const AdminRouter = (props) => {
  const { authenticated, user } = props;

  if (fp.isEmpty(user)) {
    return <Redirect to="/logout" />;
  }

  if (!isAdmin(user)) {
    return <Redirect to="/403" />;
  }

  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <Switch>
          {
            <AuthenticatedRoute
              path="/admin"
              component={AdminRoute}
              authenticated={authenticated}
              currentUser={user}
            />
          }
        </Switch>
      </div>
    </div>
  );
};

AdminRouter.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    userType: PropTypes.string
  })
};

AdminRouter.defaultProps = {
  user: {}
};

export default AdminRouter;
