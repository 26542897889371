import fp from 'lodash/fp';
import api from './api';

const adminApi = api('/admin');

export const fetchCallRecordingTracker = async (queryParams = {}) => {
  const tracker = await adminApi.get('/call-recording-tracker', { params: queryParams });

  return fp.getOr({}, 'data')(tracker);
};
