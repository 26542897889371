import React from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm
} from 'redux-form';
import EmailCheckOutlineIcon from 'mdi-react/EmailCheckOutlineIcon';

import {
  Form,
  FormGroup,
  FormField,
  FormGroupField,
  Label
} from '../../../shared/components/Form';
import RenderField from '../../../shared/components/form/RenderField';
import SubmitButton from './SubmitButton';
import forgotPasswordFormValidate from './validate';

const ForgotPasswordForm = ({ onSubmit }) => (
  <Form
    className="form"
    orientation="vertical"
    onSubmit={onSubmit}
  >
    <FormGroup style={{ marginTop: '15px', width: '31em' }}>
      <Label>Email</Label>
      <FormGroupField>
        {/* // TODO: put this in shared components */}
        <div className="form__form-group-icon">
          <EmailCheckOutlineIcon />
        </div>
        <FormField
          name="email"
          component={RenderField}
          placeholder="Enter Email"
          type="email"
        />
      </FormGroupField>
    </FormGroup>
    <SubmitButton />
  </Form>
);

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'forgot_password_form',
  validate: forgotPasswordFormValidate
})(ForgotPasswordForm);
