/* eslint-disable max-lines */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import fp from 'lodash/fp';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableSortLabel,
  Card
} from '@material-ui/core';
import { Badge } from 'reactstrap';
import ActionButton from '../ActionButton';
import ConfirmationModal from '../ConfirmationModal';

import TableToolbar from './TableToolbar';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  tableRow: {
    cursor: 'pointer',
    height: '35px !important'
  },
  tableWrapper: {
    maxHeight: '70vh',
    overflow: 'auto'
  },
  head: {
    backgroundColor: '#fafafa',
    color: theme.palette.getContrastText,
    position: 'sticky',
    top: 0
  },
  button: {
    margin: theme.spacing.unit
  }
});

const isVisitedStyle = {
  color: '#bdbdbd'
};

const isRowViewed = fp.getOr(false, 'isViewed');

/* TODO: convert this to functional component */
class KTables extends React.Component {
  static propTypes = {
    heads: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string
    })).isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    rowsCount: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    classes: PropTypes.shape({
      root: PropTypes.string,
      tableRow: PropTypes.string,
      tableWrapper: PropTypes.string,
      head: PropTypes.string,
      button: PropTypes.string
    }).isRequired,
    onLoading: PropTypes.bool.isRequired,
    onRowClick: PropTypes.func,
    onSortClick: PropTypes.func,
    onChangePage: PropTypes.func,
    onChangeRowsPerPage: PropTypes.func,
    onViewButtonClick: PropTypes.func,
    onEditButtonClick: PropTypes.func,
    actionButtons: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    onDeleteButtonClick: PropTypes.func,
    onRefreshClick: PropTypes.func,
    tableToolbar: PropTypes.shape({})
  };

  static defaultProps = {
    onRowClick: () => {},
    onSortClick: () => {},
    onChangePage: () => {},
    onChangeRowsPerPage: () => {},
    onViewButtonClick: () => {},
    onEditButtonClick: () => {},
    onDeleteButtonClick: () => {},
    onRefreshClick: () => {},
    tableToolbar: null,
    actionButtons: []
  }

  state = {
    shouldShowModal: false,
    selectedRow: {}
  };

  onRowClick = row => () => this.props.onRowClick(row);
  onViewButtonClick = row => () => this.props.onViewButtonClick(row);
  onEditButtonClick = row => () => this.props.onEditButtonClick(row);
  showModal = row =>
    this.setState({
      ...this.state,
      selectedRow: row,
      shouldShowModal: true
    })

  handleDeleteCancel = () =>
    this.setState({
      ...this.state,
      shouldShowModal: false
    })

  handleDeleteConfirm = () => {
    this.props.onDeleteButtonClick(this.state.selectedRow);
    this.setState({
      ...this.state,
      shouldShowModal: false
    });
  };

  handleSorting = key => () =>
    this.props.onSortClick({ sortBy: key });

  handleChangePage = (event, page) => {
    this.props.onChangePage({ page });
  }

  handleChangeRowsPerPage = (event) => {
    this.props.onChangeRowsPerPage({ pageSize: event.target.value });
  }

  renderDefaultTableToolbar = () => {
    const { onRefreshClick, onLoading } = this.props;
    return (<TableToolbar
      onRefreshClick={onRefreshClick}
      isLoading={onLoading}
    />
    );
  }

  renderTableToolbar = () => {
    const { tableToolbar } = this.props;
    return fp.flow(
      fp.isNil,
      isTableToolbarPropsPresent =>
        (isTableToolbarPropsPresent ? this.renderDefaultTableToolbar() : tableToolbar)
    )(tableToolbar);
  }

  renderColumnHeaders() {
    return (
      <TableRow>
        {this.props.heads.map(head => (
          <TableCell key={head.key} className={this.props.classes.head}>
            <TableSortLabel
              active={head.key === this.props.orderBy}
              direction={this.props.order}
              onClick={this.handleSorting(head.key)}
            >
              {head.name}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    );
  }

  renderPagination = () => (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25, 50]}
      component="div"
      count={this.props.total}
      rowsPerPage={this.props.rowsCount}
      page={this.props.page}
      onChangePage={this.handleChangePage}
      onChangeRowsPerPage={this.handleChangeRowsPerPage}
    />
  )

  renderRow = (row) => {
    /* TODO:
    refactor such thatwe don't have to initialize Actionbuttons
    when there is NO action props specified (this saves browser RAM usage)
    */
    const defaultActionButtons = {
      View: (
        <ActionButton
          className={this.props.classes.button}
          onClick={this.onViewButtonClick(row)}
          title="View"
          color="primary"
          size="sm"
        />
      ),
      Edit: (
        <ActionButton
          className={this.props.classes.button}
          onClick={this.onEditButtonClick(row)}
          title="Edit"
          color="secondary"
          size="sm"
        />
      ),
      Delete: (
        <ActionButton
          className={this.props.classes.button}
          onClick={() => this.showModal(row)}
          title="Delete"
          color="danger"
          size="sm"
        />
      )
    };

    const actionButonList = fp.get(row.id, this.props.actionButtons);
    const actionKeys = fp.intersection(fp.keys(defaultActionButtons), actionButonList);
    const actionButtons = actionKeys.map(key => defaultActionButtons[key]);


    const statusBadge = fp.get('isActive', row) ? (
      <Badge color="success" pill> Active </Badge>
    ) : (
      <Badge color="danger" pill> Inactive </Badge>
    );

    const componentTypeMap = {
      badge: statusBadge,
      actions: actionButtons
    };

    const textComponent = head => (
      <div style={isRowViewed(row) ? isVisitedStyle : {}}> {fp.get(head.key, row)} </div>
    );


    return this.props.heads.map(head => (
      <TableCell key={`cell.${head.key}`}>
        {fp.has('componentType', head) &&
                         fp.has(`${head.componentType}`, componentTypeMap)
                           ? componentTypeMap[head.componentType]
                           : textComponent(head)}
      </TableCell>
    ));
  };

  renderRows = () =>
    this.props.rows.map(row => (
      <TableRow
        key={row.id}
        hover
        className={this.props.classes.tableRow}
        onClick={this.onRowClick(row)}
        style={isRowViewed(row) ? isVisitedStyle : {}}
      >
        {this.renderRow(row)}
      </TableRow>
    ));

  renderDeleteModal() {
    /* TODO: this fields does not have
    anything todo with Ktables and should be
    generic as KTable component is. */
    const firstName = fp.get('firstName', this.state.selectedRow);
    const lastName = fp.get('lastName', this.state.selectedRow);

    return (
      <ConfirmationModal
        isOpen={this.state.shouldShowModal}
        title="Confirm Deletion"
        message={`Are you sure you want to remove
        ${firstName} ${lastName}?`}
        onConfirmClick={this.handleDeleteConfirm}
        onCancelClick={this.handleDeleteCancel}
      />
    );
  }

  render() {
    return (
      <Card className={this.props.classes.root}>
        {this.renderTableToolbar()}
        <div className={this.props.classes.tableWrapper}>
          <Table>
            <TableHead>{this.renderColumnHeaders()}</TableHead>
            <TableBody>
              {this.renderRows()}
            </TableBody>
          </Table>
          {this.renderDeleteModal()}
        </div>
        {this.renderPagination()}
      </Card>
    );
  }
}

export default withStyles(styles)(KTables);
