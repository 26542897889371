import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const childrenType = PropTypes.oneOfType([
  PropTypes.element,
  PropTypes.arrayOf(PropTypes.element),
  PropTypes.array
]);

export const Form = ({ children, onSubmit, orientation }) => (
  <form className={`form form--${orientation}`} onSubmit={onSubmit}>
    {children}
  </form>
);

Form.propTypes = {
  children: childrenType.isRequired,
  onSubmit: PropTypes.func,
  orientation: PropTypes.oneOf(['horizontal', 'vertical'])
};

Form.defaultProps = {
  onSubmit: () => {},
  orientation: 'horizontal'
};

export const FormGroup = ({ children, ...props }) => (
  <div className="form__form-group" {...props}>{children}</div>
);

export const FormHalf = ({ children }) => (
  <div className="form__half">{children}</div>
);

// TODO: create interfaces for reoccuring prop types
FormHalf.propTypes = {
  children: childrenType.isRequired
};

export const FormGroupField = ({ children, ...props }) => (
  <div className="form__form-group-field" {...props}>{children}</div>
);
FormGroupField.propTypes = {
  children: childrenType.isRequired
};

export const Label = ({ children }) => (
  <span className="form__form-group-label">{children}</span>
);

FormGroup.propTypes = {
  children: childrenType.isRequired
};


Label.propTypes = {
  children: PropTypes.string.isRequired
};

export const FormField = props => (
  <div className="form__form-group-field">
    <Field {...props} />
  </div>
);
