/* eslint-disable max-lines */
// TODO: create helpers directory and segregate those methods that are model specific.
import fp from 'lodash/fp';
import moment from 'moment';
import { getFormValues } from 'redux-form';
import { TIMEFORMAT } from '../../constants/date-format';
import { CHANNEL, TYPES } from '../../constants/call-recording-actions';
import { SKIP_TIME } from '../../constants/app-config';

export const structureById = fp.keyBy('id');

export const isValidDateFormat = (date, format) => moment(date, format, true).isValid();

export const transformCallRecording = (callRecording = {}) => {
  if (fp.isEmpty(callRecording)) {
    throw EvalError('Call Recording object must not be empty');
  }
  const callLength = fp.get('callLengthSeconds', callRecording) * 1000;
  const startTime = fp.get('startTime', callRecording);
  const endTime = fp.getOr(null, 'endTime', callRecording);
  const createdAt = fp.get('createdAt', callRecording);
  const ingestedAt = fp.get('ingestedAt', callRecording);

  return {
    ...callRecording,
    callLengthSeconds: moment.utc(callLength).format(TIMEFORMAT.HMSS),
    createdAt: moment.utc(createdAt).format(TIMEFORMAT.YMD),
    dateRecorded: moment.utc(startTime).format('LL'),
    startTime: moment(startTime).format(TIMEFORMAT.YMDHMSS),
    endTime: endTime ? moment(endTime).format(TIMEFORMAT.YMDHMSS) : endTime,
    ingestedAt: moment.utc(ingestedAt).format(TIMEFORMAT.LHMS)
  };
};

export const filterFields = (form = {}) => {
  const updatedFields = fp.reduce((accumulator, [key, value]) => {
    if (value.visited) {
      return fp.merge(accumulator, fp.pick(key, form.values));
    }

    return accumulator;
  }, {});

  return fp.flow(
    fp.toPairs,
    updatedFields
  )(form.fields);
};

export const transformUser = (data = {}) => {
  const updatedAt = fp.get('updatedAt', data);
  const createdAt = fp.get('createdAt', data);

  return {
    ...data,
    updatedAt: moment(updatedAt).format('LLL'),
    createdAt: moment(createdAt).format('LL')
  };
};

export const invertDirection = (data) => {
  const invertion = {
    asc: 'desc',
    desc: 'asc'
  };
  return invertion[fp.lowerCase(data)];
};

export const transformSelectOptions = fp.map(option => ({
  value: option.id,
  label: option.name
}));

export const getUserRoles = fp.flow(
  fp.get('userRoles'),
  fp.map('role')
);

const isKombeaAdmin = fp.flow(
  fp.get('userRoles'),
  fp.some({ level: 4 })
);

const isSuperAdmin = fp.flow(
  fp.get('userRoles'),
  fp.some({ level: 5 })
);

export const getUserRole = (user = {}, orgId) => {
  const roles = fp.getOr([], 'userRoles')(user);

  if (isSuperAdmin(user)) {
    return fp.flow(
      fp.filter({ level: 5 }),
      fp.head
    )(roles);
  }

  if (isKombeaAdmin(user)) {
    return fp.flow(
      fp.filter({ level: 4 }),
      fp.head
    )(roles);
  }

  const userRole = fp.flow(
    fp.filter({ clientId: orgId }),
    fp.head
  )(roles);

  return !fp.isEmpty(userRole) ? userRole : {};
};

export const getSelectedProcess = fp.flow(
  getFormValues('call_recording_filter_form'),
  fp.getOr(null, 'process_id.value')
);

export const normalizeMultiSelectOptionValues = fp.map('value');

export const populateDataField = (filterValues = {}) => {
  const dataFieldKeys = fp.keys(filterValues);

  return fp.reduce(
    (accumulator, key) =>
      (fp.includes('data', key)
        ? {
          ...accumulator,
          [key]: {
            in: fp.isArray(filterValues[key].in)
              ? fp.map('value')(filterValues[key].in)
              : [filterValues[key].in.trim()]
          }
        }
        : accumulator),
    {}
  )(dataFieldKeys);
};

export const isValidDate = value =>
  isValidDateFormat(value, TIMEFORMAT.YMDTHMSSZ) ||
  isValidDateFormat(value, 'LL');

export const parseNumber = value => parseInt(value, 10);

export const normalizeCallRecordingFilters = fp.flow(
  fp.toPairs,
  fp.reduce((filter, [key, operation]) => {
    if (fp.isObject(operation)) {
      // inner objects
      const transformedFilters = fp.flow(
        fp.toPairs,
        fp.reduce((acc, [operand, value]) => {
          if (fp.isEmpty(value)) {
            return acc;
          }
          if (isValidDate(value) && !fp.isNil(operand) && operand === 'gte') {
            return {
              ...acc,
              [key]: {
                ...acc[key],
                gte: moment(value)
                  .startOf('day')
                  .format()
              }
            };
          }
          // if this is date && lte
          if (isValidDate(value) && !fp.isNil(operand) && operand === 'lte') {
            return {
              ...acc,
              [key]: {
                ...acc[key],
                lte: moment(value)
                  .startOf('day')
                  .format()
              }
            };
          }

          // Number checking
          if (
            operand === 'gte' ||
            (operand === 'lte' && !fp.isNaN(parseNumber(value)))
          ) {
            const intVal = parseNumber(value);
            return fp.isNaN(intVal)
              ? filter
              : {
                ...acc,
                [key]: {
                  ...acc[key],
                  [operand]: parseNumber(value)
                }
              };
          }

          // default
          return {
            ...acc,
            [key]: {
              [operand]: value
            }
          };
        }, {})
      )(operation);

      return { ...filter, ...transformedFilters };
    }
    return {
      ...filter,
      [key]: operation
    };
  }, {})
);

export const composeMetaData = (state, payload, shouldOrderInvert) => {
  const sortOrder = shouldOrderInvert ?
    invertDirection(state.sortOrder) : state.sortOrder;

  const meta = {
    page: fp.getOr(state.page, 'page')(payload),
    pageSize: fp.getOr(state.pageSize, 'pageSize')(payload),
    sortBy: fp.getOr(state.sortBy, 'sortBy')(payload),
    sortOrder
  };

  return meta;
};

export const getDateLengthInSeconds = (baseDate, date) => {
  const formattedDate = moment(date);
  const formattedBaseDate = moment(baseDate);

  const lengthInMs = formattedDate.diff(formattedBaseDate, 'milliseconds');

  return lengthInMs / 1000;
};

export const formatTime = time => (time ? moment(time).format(TIMEFORMAT.YMDHMSS) : time);

export const composeCallRecordingAction = (action = {}) => ({
  ...action,
  startTime: formatTime(fp.get('startTime', action)),
  endTime: formatTime(fp.get('endTime', action))
});

export const filterDataForAgentActions = fp.filter((action) => {
  const filter = {
    channelId: CHANNEL.AGENT.id
  };

  const isEqualToFilter = fp.flow(
    fp.pick(['channelId']),
    fp.isEqual(filter)
  )(action);

  return isEqualToFilter;
});

export const isLesserThanTime = ({ startTime, endTime }, threshold) => {
  const portionLength = moment(endTime) - moment(startTime);
  const portionLengthInSeconds = portionLength / 1000;

  return fp.lte(threshold, portionLengthInSeconds);
};

export const filterDataForFastMode = fp.filter((action) => {
  const filter = {
    channelId: CHANNEL.PROTOCALL.id,
    type: TYPES.MESSAGE
  };

  const isEqualToFilter = fp.flow(
    fp.pick(['channelId', 'type']),
    fp.isEqual(filter)
  )(action);

  return isEqualToFilter && isLesserThanTime(action, SKIP_TIME);
});

export const getSelectedLength = fp.flow(
  fp.values,
  fp.drop,
  fp.size
);

export const getLoadingMessage = (hasFetch, values) => {
  if (hasFetch && fp.isEmpty(values)) {
    return 'no results found';
  }
  return 'searching...';
};

export const transformTrackerResponse = (callRecordingTracker = {}) => {
  if (fp.isEmpty(callRecordingTracker)) {
    throw EvalError('Call Recording Tracker object must not be empty');
  }

  const timestamp = moment(fp.get('timestamp', callRecordingTracker))
    .format(TIMEFORMAT.YMDHMSS);

  return {
    ...callRecordingTracker,
    timestamp
  };
};
