import axios from 'axios';
import humps from 'humps';
import moment from 'moment';
import fp from 'lodash/fp';
import { DATA_FIELD_EXEMPTION } from '../constants/app-config';

export default (mainRoutePath) => {
  const apiInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}${mainRoutePath}`,
    headers: {
      credentials: 'same-origin'
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    transformResponse: [
      ...axios.defaults.transformResponse,
      data => humps.camelizeKeys(data)
    ],
    transformRequest: [
      data => humps.decamelizeKeys(data),
      ...axios.defaults.transformRequest
    ]
  });

  apiInstance.interceptors.request.use((config) => {
    const sortingParams = ['sortBy', 'sortOrder'];

    const sortingData = fp.flow(
      fp.pick(sortingParams),
      (param) => {
        if (fp.isEmpty(param)) {
          return {};
        }
        return {
          sortBy: fp.includes(param.sortBy, DATA_FIELD_EXEMPTION) ?
            param.sortBy : fp.snakeCase(param.sortBy),
          sortOrder: fp.lowerCase(param.sortOrder)
        };
      }
    )(config.params);

    const startTime = fp.flow(
      fp.pick('start_time'),
      (param) => {
        if (fp.isEmpty(param)) {
          return {};
        }

        const startTimeGTE = fp.get('start_time.gte', param);
        const startTimeLTE = fp.get('start_time.lte', param);

        return {
          startTime: {
            ...(fp.isEmpty(startTimeGTE)) ? {} : {
              gte: `${moment(startTimeGTE).startOf('day').format()}`
            },
            ...(fp.isEmpty(startTimeLTE)) ? {} : {
              lte: `${moment(startTimeLTE).endOf('day').format()}`
            }
          }
        };
      }
    )(config.params);

    const transformedParams = {
      ...humps.decamelizeKeys(config.params),
      ...humps.decamelizeKeys(sortingData),
      ...humps.decamelizeKeys(startTime)
    };

    // eslint-disable-next-line no-param-reassign
    config.params = transformedParams;

    return config;
  });

  apiInstance.interceptors.response.use(response => response, ((error) => {
    const message = fp.get('message')(error.response.data);
    const statusCode = error.response.status;

    if (fp.isNil(error.response)) {
      window.location = '/500';
    }
    if (statusCode === 404 && message === 'Not Found') {
      window.location = '/404';
    }
    if (statusCode === 401 && (message.toLowerCase().indexOf('invalid') > -1)) {
      window.location = '/logout';
    }
    if (statusCode === 401 && (message.toLowerCase().indexOf('expired') > -1)) {
      window.location = '/logout';
    }
    if (statusCode === 401 && message.toLowerCase().indexOf('unauthorized') > -1) {
      window.location = '/401';
    }

    throw error;
  }));

  return apiInstance;
};
