import fp from 'lodash/fp';
import deepMapKeys from 'deep-map-keys';

export const camelizeKeys = obj => deepMapKeys(obj, fp.camelCase);
export const toSnakeCase = data => deepMapKeys(data, fp.snakeCase);

export const normalize = fp.flow(
  fp.getOr({}, 'data'),
  camelizeKeys
);
