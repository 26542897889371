import React, { useEffect } from 'react';
import fp from 'lodash/fp';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import store from '../../redux/store';


const UUIDRedirect = ({
  match,
  fetchCallRecordingByUUID,
  callRecording,
  isCallRecordingByIDFetching
}) => {
  const uuid = fp.get('params.uuid', match);

  useEffect(() => {
    fetchCallRecordingByUUID(uuid);
  }, []);

  const renderRediract = () => {
    if (callRecording.id) {
      return <Redirect to={`/call-recordings/${callRecording.id}`} />;
    }
    return null;
  };

  return (
    <React.Fragment>
      {
        isCallRecordingByIDFetching && !callRecording.id ?
          'Loading...' :
          renderRediract()
      }
    </React.Fragment>


  );
};

UUIDRedirect.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    })
  }).isRequired,
  fetchCallRecordingByUUID: PropTypes.func.isRequired,
  callRecording: PropTypes.shape({
    callLengthSeconds: PropTypes.string,
    agentName: PropTypes.string,
    dateRecorded: PropTypes.string,
    fileName: PropTypes.string
  }),
  isCallRecordingByIDFetching: PropTypes.bool.isRequired
};

UUIDRedirect.defaultProps = {
  callRecording: {}
};

const mapDispatchToProps = dispatch => ({
  fetchCallRecordingByUUID: dispatch.callRecordings.fetchCallRecordingByUUID
});

const mapStateToProps = (state, ownProps) => console.log('#DEBUG', { state, ownProps }) ||
({
  callRecording: store
    .select
    .callRecordings
    .getCallRecordingByUUID(ownProps.match.params.uuid)(state),
  isCallRecordingByIDFetching: state
    .loading
    .effects
    .callRecordings
    .fetchCallRecordingByUUID
});


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UUIDRedirect));
