import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import fp from 'lodash/fp';

export default class ModalComponent extends PureComponent {
  static propTypes = {
    buttonClassName: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.node.isRequired,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    buttonTitle: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    isButtonDisable: PropTypes.bool,
    toolTipMessage: PropTypes.string
  };

  static defaultProps = {
    buttonClassName: '',
    colored: false,
    header: false,
    isButtonDisable: false,
    onSubmit: () => {},
    title: '',
    toolTipMessage: ''
  };

  constructor() {
    super();
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    const {
      color, buttonTitle, title, message, colored, header, size,
      onSubmit, isButtonDisable, buttonClassName, toolTipMessage
    } = this.props;
    let Icon;

    switch (color) {
      case 'primary':
        Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
        break;
      case 'success':
        Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
        break;
      case 'warning':
        Icon = <span className="lnr lnr-flag modal__title-icon" />;
        break;
      case 'danger':
        Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header
    });

    return (
      <>
        {fp.isEmpty(toolTipMessage) || !isButtonDisable ?
          <Button
            className={buttonClassName}
            color={color}
            onClick={this.toggle}
            size={size}
            disabled={isButtonDisable}
          >
            {buttonTitle}
          </Button> :
          <Tooltip title={toolTipMessage}>
            <span>
              <Button
                className={buttonClassName}
                color={color}
                onClick={this.toggle}
                size={size}
                disabled={isButtonDisable}
              >
                {buttonTitle}
              </Button>
            </span>
          </Tooltip>}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={`modal-dialog--${color} ${modalClass}`}
        >
          <div className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              onClick={this.toggle}
              size={size}
            />
            {header ? '' : Icon}
            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">{message}</div>
          <ButtonToolbar className="modal__footer">
            <Button onClick={this.toggle} size={size}>Close</Button>{' '}
            {fp.isEmpty(onSubmit) ? <span /> :
            <Button
              outline={colored}
              color={color}
              onClick={onSubmit}
              size={size}
            >Ok
            </Button>}
          </ButtonToolbar>
        </Modal>
      </>
    );
  }
}
