import React from 'react';
import PropTypes from 'prop-types';
import fp from 'lodash/fp';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
  FormGroup,
  Label,
  FormField
} from '../../../../shared/components/Form';
import KSelect from '../../../../shared/components/KSelect';
import store from '../../../../redux/store';
import { getSelectedLength, getLoadingMessage } from '../../../../redux/models/helpers';

const Client = ({
  clientHasFetched,
  clientDropdownValues,
  isClientDropdownValuesLoading,
  fetchClientDropdownValues,
  onFilter,
  resetSection,
  resetAddedFilterColumn,
  resetSelectedProcess,
  resetFilterDropDownValues,
  resetClientDropDownValues,
  resetProcessDropDownValues
}) => {
  const handleClientOnChange = (value) => {
    const selectedClientLength = getSelectedLength(value);

    // reset/clear agent, process and added filter
    resetSection('agent_id[in]');
    resetSection('process_id');
    resetSection('add_filter');

    resetFilterDropDownValues();
    resetAddedFilterColumn();
    resetSelectedProcess();
    resetProcessDropDownValues();

    // fetch Client when no selected process value
    if (selectedClientLength === 0) {
      fetchClientDropdownValues(value);
    }

    // update call recording results
    onFilter(value);
  };

  const handleClientBlur = (event) => {
    event.preventDefault();

    if (!fp.isEmpty(event.target.value)) {
      resetClientDropDownValues();
    }
  };

  return (
    <FormGroup>
      <Label>Client</Label>
      <FormField
        className="form__form-group-select"
        component={KSelect}
        name="client_key[in]"
        isMulti
        isLoading={isClientDropdownValuesLoading}
        noResultsText={getLoadingMessage(clientHasFetched, clientDropdownValues)}
        options={clientDropdownValues}
        onChange={handleClientOnChange}
        onSearch={value => fetchClientDropdownValues({ value, type: 'ilike' })}
        onFocus={fetchClientDropdownValues}
        onBlur={handleClientBlur}
        placeHolder="Select Clients..."
      />
    </FormGroup>
  );
};

const optionPropType = PropTypes.shape({
  value: PropTypes.number,
  label: PropTypes.string
});

Client.propTypes = {
  clientHasFetched: PropTypes.bool.isRequired,
  clientDropdownValues: PropTypes.arrayOf(optionPropType),
  isClientDropdownValuesLoading: PropTypes.bool.isRequired,
  fetchClientDropdownValues: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  resetSection: PropTypes.func.isRequired,
  resetFilterDropDownValues: PropTypes.func.isRequired,
  resetSelectedProcess: PropTypes.func.isRequired,
  resetAddedFilterColumn: PropTypes.func.isRequired,
  resetClientDropDownValues: PropTypes.func.isRequired,
  resetProcessDropDownValues: PropTypes.func.isRequired
};

Client.defaultProps = {
  clientDropdownValues: []
};

const mapDispatchToProps = dispatch => ({
  fetchClientDropdownValues: dispatch.filterPanel.fetchClientDropdownValues,
  resetFilterDropDownValues: dispatch.filterPanel.resetDataFields,
  resetSelectedProcess: dispatch.globalConfig.resetSelectedProcess,
  resetProcessDropDownValues: dispatch.filterPanel.resetProcess,
  resetClientDropDownValues: dispatch.filterPanel.resetClient,
  resetAddedFilterColumn: dispatch.globalConfig.resetAddedFilter
});

const mapStateToProps = state => ({
  clientDropdownValues: store.select.filterPanel.allClients(state),
  clientHasFetched: state.filterPanel.hasFetched.clients,
  isClientDropdownValuesLoading: state.loading.effects.filterPanel
    .fetchClientDropdownValues
});

export default fp.flow(
  reduxForm({
    form: 'call_recording_filter_form',
    destroyOnUnmount: false
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Client);
