import fp from 'lodash/fp';
import {
  structureById,
  composeMetaData,
  transformTrackerResponse
} from './helpers';
import * as callRecordingTrackerService from '../../services/call-recording-tracker';

const defaultState = {
  all: {},
  sortBy: 'timestamp',
  sortOrder: 'desc',
  page: 0,
  pageSize: 25,
  total: 0
};

export default {
  state: defaultState,

  reducers: {
    setCallRecordingTracker(state, response) {
      const { data, meta } = response;
      const transformedTrackerValues = data.map(transformTrackerResponse);
      const trackerValues = { all: structureById(transformedTrackerValues) };
      const tracker = fp.merge(trackerValues, meta);

      return fp.assign(state, tracker);
    },
    clearAll() {
      return defaultState;
    }
  },

  effects: {
    async fetchCallRecordingTrackers(payload = {}, state) {
      const shouldInvertSort = !fp.isEmpty(payload) && fp.has('sortBy', payload);
      const metaData = composeMetaData(
        state.callRecordingTracker,
        payload,
        shouldInvertSort
      );

      const callRecordingTracker = await callRecordingTrackerService
        .fetchCallRecordingTracker(metaData);

      this.setCallRecordingTracker(callRecordingTracker);
    },
    resetCallRecordingTracker() {
      this.clearAll();
    }
  },
  selectors: (slice, createSelector, hasProps) => ({
    allValues: hasProps((models, { sortBy, sortOrder }) => slice(fp.flow(
      fp.get('all'),
      fp.values,
      fp.orderBy(sortBy, sortOrder)
    )))
  })
};
