import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const List = () => (
  <Container className="callrecording">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Manage Filters</h3>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <h5>This is the index page for Filter Management Route</h5>
      </Col>
    </Row>
  </Container>
);

export default List;
