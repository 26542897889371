import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, reset } from 'redux-form';
import { lifecycle, defaultProps } from 'recompose';
import { Redirect } from 'react-router-dom';
import fp from 'lodash/fp';
import { connect } from 'react-redux';
import store from '../../../redux/store';

const UserLogoutComponent = ({ isLoggedIn }) => (
  <div>
    {!isLoggedIn ? <Redirect to="/" /> : '' }
  </div>
);

UserLogoutComponent.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isLoggedIn: store.select.user.isLoggedIn(state)
});

const mapDispatchToProps = dispatch => ({
  handleLogout: dispatch.user.logout
});

export default fp.flow(
  reduxForm({
    form: 'call_recording_filter_form'
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleLogout();
      reset();
    }
  }),
  defaultProps({
    handleLogout: () => {}
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(UserLogoutComponent);
