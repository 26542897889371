/* eslint-disable max-lines */
import fp from 'lodash/fp';
import axios from 'axios';
import { getPersistor } from '@rematch/persist';

import {
  structureById,
  invertDirection,
  transformUser,
  filterFields
} from './helpers';
import validateActionButtons from '../../utils/user';
import * as userService from '../../services/user';

const defaultState = {
  all: {},
  currentUser: {},
  error: {},
  sortBy: 'lastName',
  sortOrder: 'asc',
  page: 0,
  pageSize: 25,
  total: 0,
  actionButtons: {}
};

export default {
  state: defaultState,

  reducers: {
    getUsers(state, response) {
      const transformedUserValues = response.data.map(transformUser);
      const userValues = { all: structureById(transformedUserValues) };
      const users = fp.merge(userValues, response.meta);

      return fp.assign(state, users);
    },
    setActionButtons(state, payload) {
      const { currentUser } = state;
      const validatedActionButtons = validateActionButtons(currentUser, payload.data);

      return fp.merge(state, { actionButtons: validatedActionButtons });
    },
    getUser(state, payload) {
      const transformedUserValues = transformUser(payload);
      return fp.merge(state, { all: { [payload.id]: transformedUserValues } });
    },
    logoutUser() {
      localStorage.removeItem('persist:root');
      return defaultState;
    },
    updateUserDetails(state, payload) {
      return fp.merge(state, {
        all: {
          [payload.id]: payload
        }
      });
    },
    removeUser(state, id) {
      return fp.assign(state, { all: fp.omit([id], state.all) });
    },
    storeLoginUser(state, userInfo) {
      // TOFIX: THIS SHOULD BE temporary! ref:  ODF#1600
      const fsQAUsers = [
        'fsqaone@gmail.com',
        'fsqatwo@gmail.com'
      ];
      const user = fp.merge(state, {
        currentUser: {
          ...userInfo,
          // TOFIX: THIS SHOULD BE temporary! ref:  ODF#1600
          isFiveStrataQA: fsQAUsers.includes(userInfo.email)
        }
      });
      localStorage.setItem(
        'persist:root',
        JSON.stringify({ user: JSON.stringify(user) })
      );
      axios.defaults.headers.common.Authorization = `Bearer ${userInfo.accessToken}`;

      return user;
    },
    storeError(state, error = {}) {
      return fp.assign(state, {
        error: {
          message: fp.getOr(null, 'message', error),
          statusCode: fp.getOr(null, 'statusCode', error),
          error: fp.getOr(null, 'error', error)
        }
      });
    }
  },

  effects: {
    async fetchUsers(payload = {}, state) {
      // TODO: centralize data manipulation in one function for sorting and paging
      const sorting = fp.merge(
        { sortBy: fp.getOr(state.user.sortBy, 'sortBy')(payload) },
        {
          sortOrder:
            !fp.isEmpty(payload) && fp.has('sortBy', payload)
              ? invertDirection(state.user.sortOrder)
              : state.user.sortOrder
        }
      );

      // pagination
      const pageSize = fp.getOr(state.user.pageSize, 'pageSize')(payload);
      const page = fp.isEqual(pageSize, state.user.pageSize)
        ? fp.get('page')(payload)
        : 0;

      const pagination = { page, pageSize };

      const queryParams = fp.merge(sorting, pagination);
      const users = await userService.fetchUsers(queryParams);

      this.getUsers(users);
      this.setActionButtons(users);
    },

    async fetchUser(id) {
      try {
        const user = await userService.fetchUser(id);
        this.getUser(user);
      } catch (error) {
        throw error;
      }
    },

    async createUser(payload = {}, state) {
      try {
        const orgId = fp.get('organizationId')(state.user.currentUser);

        const composedPayload = {
          ...payload,
          organization_id: orgId,
          user_acl: {
            organization_id: orgId,
            user_role_id: 1
          }
        };

        const addUserResponse = await userService.createUser(composedPayload);
        return addUserResponse;
      } catch (error) {
        const { response } = error;
        this.storeError(response.data);
        throw error;
      }
    },

    async updateUser(payload, state) {
      const userForm = fp.get('form.user_form', state);
      const fieldsToUpdate = filterFields(userForm);

      try {
        const id = fp.get('id', payload);
        const userUpdateResponse = await userService.updateUser(fieldsToUpdate, id);

        return this.updateUserDetails(userUpdateResponse);
      } catch (error) {
        const { response } = error;
        this.storeError(response.data);
        throw error;
      }
    },
    logout() {
      getPersistor().purge(['user']);
      return this.logoutUser();
    },
    async delete(payload) {
      try {
        const userId = fp.get('id', payload);
        const userDeleted = await userService.deleteUser(userId);

        return this.removeUser(userDeleted.id);
      } catch (error) {
        throw error;
      }
    },
    async postLogin(payload) {
      try {
        const loginResponse = await userService.postLogin(payload);

        const storeLoginUser = this.storeLoginUser(loginResponse.data);

        return storeLoginUser;
      } catch (error) {
        const { response } = error;
        if (
          fp.toLower(fp.get('data.message', response)) === 'invalid request payload input'
        ) {
          this.storeError({
            ...response.data,
            message: 'Please provide a valid e-mail'
          });
          throw error;
        }
        this.storeError(response.data);
        throw error;
      }
    },
    async forgotPassword(payload) {
      try {
        await userService.forgotPassword(payload);
      } catch (error) {
        const { response } = error;
        this.storeError(response.data);
        throw error;
      }
    },
    async resetPassword(verificationCode, state) {
      try {
        const resetPasswordForm = fp.get('form.reset_password_form', state);
        const resetPasswordFilterField = filterFields(resetPasswordForm);
        const { password, confirm_password: confirmPassword } = resetPasswordFilterField;

        const payload = {
          password,
          confirmPassword,
          verificationCode
        };

        await userService.updateUserPassword(payload);

        return null;
      } catch (error) {
        const { response } = error;
        this.storeError(response.data);
        throw error;
      }
    },

    async verifyVerificationCode(code) {
      try {
        const queryParam = {
          verificationCode: code
        };

        const response = await userService.verifyVerificationCode(queryParam);
        return response;
      } catch (error) {
        const { response } = error;
        this.storeError(response.data);
        throw error;
      }
    }
  },

  selectors: (slice, createSelector, hasProps) => ({
    all() {
      return createSelector(
        slice,
        fp.get('all')
      );
    },
    error() {
      return createSelector(
        slice,
        fp.get('error')
      );
    },
    currentUser() {
      return createSelector(
        slice,
        fp.get('currentUser')
      );
    },
    isLoggedIn() {
      return createSelector(
        this.currentUser,
        currentUser => !fp.isEmpty(currentUser)
      );
    },
    allValues: hasProps((models, { sortBy, sortOrder }) =>
      slice(fp.flow(
        fp.get('all'),
        fp.values,
        fp.orderBy(sortBy, sortOrder)
      ))),
    getUser: hasProps((models, id) =>
      slice(fp.flow(
        fp.get('all'),
        fp.get(id)
      )))
  })
};
