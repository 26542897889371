import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import fp from 'lodash/fp';
import axios from 'axios';

import MainWrapper from './MainWrapper';
import WrappedRoutes from './WrappedRouter';
import AdminRoutes from './AdminRouter';
import LogIn from '../components/LogIn';
import GenericErrorPage from '../components/GenericErrorPage';
import UserLogout from './User/logout/';
import ForgotPassword from '../components/User/ForgotPassword';
import ResetPasswordLayout from '../components/User/ResetPassword';

const Router = () => {
  const persistRoot = localStorage.getItem('persist:root');
  const user = !fp.isNil(persistRoot)
    ? JSON.parse(fp.get('user', JSON.parse(persistRoot)))
    : {};
  const currentUser = fp.get('currentUser', user);
  const authed = !fp.isEmpty(currentUser);

  if (authed) {
    axios.defaults.headers.common.Authorization = `Bearer ${currentUser.accessToken}`;
  }

  return (
    <MainWrapper>
      <main>
        <Switch>
          <Route exact path="/" component={LogIn} />
          <Route exact path="/logout" component={UserLogout} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route
            exact
            path="/reset-password/verify"
            component={ResetPasswordLayout}
          />
          <Route
            exact
            path="/log_in"
            render={() =>
              (
                authed ?
                  <Redirect to="/dashboard" /> :
                  <Route path="/" component={LogIn} />
              )
            }
          />
          <Route
            path="/admin"
            render={() => <AdminRoutes authenticated={authed} user={currentUser} />}
          />
          <Route path="/" render={() => <WrappedRoutes authenticated={authed} />} />
          <Route
            path="/404"
            component={() => <GenericErrorPage title="Not Found" statusCode="404" />}
          />
          <Route
            path="/403"
            component={() => (<GenericErrorPage
              title="Forbidden"
              statusCode="403"
            />)}
          />
          <Route
            path="/500"
            component={() => (
              <GenericErrorPage
                title="Internal Server Error"
                statusCode="500"
              />
            )}
          />
        </Switch>
      </main>
    </MainWrapper>
  );
};
export default Router;
