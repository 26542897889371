import { REHYDRATE } from 'redux-persist/lib/constants';

const initialState = {
  rehydrated: false
};

export default function (state = initialState, action) {
  if (action.type === REHYDRATE || action.type === 'user/logout') {
    return { ...state, ...action.payload, rehydrated: true };
  }
  return state;
}
