const CHANNEL = {
  PROTOCALL: {
    id: 1,
    name: 'ProtoCall'
  },
  CUSTOMER: {
    id: 2,
    name: 'Customer'
  },
  AGENT: {
    id: 3,
    name: 'Agent'
  }
};

const TYPES = {
  MESSAGE: 'message',
  PROCESS: 'process',
  TALKING: 'talking',
  HOTKEY: 'hotkey'
};

module.exports = { CHANNEL, TYPES };
