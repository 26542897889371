import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const AdminDashboard = () => (
  <Container className="callrecording">
    <Row>
      <Col md={12}>
        <h3 className="page-title">ADMIN DASHBOARD</h3>
      </Col>
    </Row>
    <Row>ADMIN DASHBOARD</Row>
  </Container>
);

export default AdminDashboard;
