import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

export default class ActionButton extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    isDisabled: PropTypes.bool
  };

  static defaultProps = {
    title: '',
    size: '',
    color: '',
    className: '',
    onClick: () => {},
    isDisabled: false
  };

  render() {
    const {
      title, size, color, onClick, className, isDisabled
    } = this.props;

    return (
      <Button
        size={size}
        outline
        color={color}
        onClick={onClick}
        className={className}
        disabled={isDisabled}
      >
        {title}
      </Button>
    );
  }
}
