import fp from 'lodash/fp';

import * as callRecordingActionsService from '../../services/call-recording-actions';
import { select } from '../store';
import {
  filterDataForFastMode,
  filterDataForAgentActions,
  composeCallRecordingAction,
  getDateLengthInSeconds
} from './helpers';

export default {
  // Todo: assign this to defaultState
  state: {
    all: {},
    hasFetchedCallRecordingActions: false,
    fastModeData: [],
    regionData: []
  },

  reducers: {
    setHasFetchCallRecordingAction(state, status) {
      return fp.merge(state, {
        hasFetchedCallRecordingActions: status
      });
    },
    clearHasFetchedCallRecordingAction(state) {
      return fp.merge(state, {
        hasFetchedCallRecordingActions: false
      });
    },
    loadCallRecordingActions(state, response) {
      return {
        all: {
          ...state.all,
          [fp.head(response).callRecordingId]: fp.flow(
            fp.map(composeCallRecordingAction),
            fp.groupBy('channelName'),
            fp.entries,
            fp.reduce(
              (obj, [channelName, actions]) => ({
                ...obj,
                ...fp.merge(obj[channelName], {
                  [channelName]: fp.keyBy('startTime', actions)
                })
              }),
              {}
            )
          )(response)
        }
      };
    },
    loadFastModeData(state, response, callRecording) {
      const { startTime: recordingStartTime } = callRecording;

      return fp.merge(
        state,
        {
          fastModeData: {
            ...state.fastModeData,
            [fp.head(response).callRecordingId]: fp.flow(
              filterDataForFastMode,
              fp.map(composeCallRecordingAction),
              fp.map((action) => {
                const startTime = fp.get('startTime', action);
                const endTime = fp.get('endTime', action);

                const startLengthInSecs = getDateLengthInSeconds(
                  recordingStartTime,
                  startTime
                );
                const endLengthInSecs = getDateLengthInSeconds(
                  recordingStartTime,
                  endTime
                );
                return [startLengthInSecs, endLengthInSecs];
              })
            )(response)
          }
        }
      );
    },
    loadRegionData(state, response, callRecording) {
      const { startTime: recordingStartTime } = callRecording;

      return fp.merge(
        state,
        {
          regionData: {
            ...state.regionData,
            [fp.head(response).callRecordingId]: fp.flow(
              filterDataForAgentActions,
              fp.map(composeCallRecordingAction),
              fp.map((action) => {
                const startTime = fp.get('startTime', action);
                const value = fp.get('value', action);
                const type = fp.get('type', action);

                const startLengthInSecs = getDateLengthInSeconds(
                  recordingStartTime,
                  startTime
                );
                const endLengthInSecs = startLengthInSecs + 0.1;
                return {
                  start: startLengthInSecs,
                  end: endLengthInSecs,
                  value,
                  type
                };
              })
            )(response)
          }
        }
      );
    }
  },

  effects: {
    async fetchActionsForCallRecording(callRecordingId, state) {
      const callRecording =
        select
          .callRecordings
          .getCallRecording(callRecordingId)(state);

      const callRecordingActions = await callRecordingActionsService
        .fetchCallRecordingActions({
          callRecordingId
        });

      this.loadCallRecordingActions(callRecordingActions);
      this.loadFastModeData(callRecordingActions, callRecording);
      this.loadRegionData(callRecordingActions, callRecording);
      this.setHasFetchCallRecordingAction(true);
    },
    resetHasFetchedCallRecordingAction() {
      this.clearHasFetchedCallRecordingAction();
    }
  },

  selectors: (slice, createSelector, hasProps) => ({
    all() {
      return createSelector(
        slice,
        fp.get('all')
      );
    },
    allValues() {
      return createSelector(
        this.all,
        fp.values
      );
    },
    getAgentActionsForCrId: hasProps((models, callRecordingId) => slice(fp.flow(
      fp.get('all'),
      fp.get(callRecordingId),
      fp.get('Agent'),
      (agentActions) => {
        const actionsToPairs = fp.toPairs(agentActions);
        return fp.reduce(
          (actionsByStartTime, [startTime, objValue]) => {
            const actionType = fp.get('type', objValue).toLowerCase();
            if (fp.includes(actionType, ['hotkey', 'mouse'])) {
              return { ...actionsByStartTime, [startTime]: fp.get('value', objValue) };
            }

            return actionsByStartTime;
          },
          {}
        )(actionsToPairs);
      }
    ))),
    getFastModeDataForCrId: hasProps((models, callRecordingId) =>
      slice(fp.flow(
        fp.get('fastModeData'),
        fp.get(callRecordingId)
      ))),
    getRegionDataForCrId: hasProps((models, callRecordingId) =>
      slice(fp.flow(
        fp.get('regionData'),
        fp.get(callRecordingId)
      ))),
    hasFetchedCallRecordingActions() {
      return createSelector(
        slice,
        fp.get('hasFetchedCallRecordingActions')
      );
    }
  })
};
