import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import { withRouter } from 'react-router-dom';
import UserEditDetails from '../../../components/User/EditDetails';
import SubmitButton from '../../../components/User/EditDetails/SubmitButton';

const UserEditDetailsWithRouter = withRouter(UserEditDetails);

const ShowUserDetails = () => (
  <Container>
    <Row>
      <Col md={12} xs={12} lg={12} xl={12}>
        <Row>
          <Col sm={6} md={6} xs={6} lg={6} xl={6}>
            <h3 className="page-title">Edit User Details</h3>
          </Col>
          <Col sm={6} md={6} xs={6} lg={6} xl={6}>
            <SubmitButton />
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <UserEditDetailsWithRouter />
    </Row>
  </Container>
);

export default ShowUserDetails;
