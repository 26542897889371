import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import TopbarMenuLink from './TopbarMenuLink';
import store from '../../../redux/store';

// const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

class TopbarProfile extends PureComponent {
  static propTypes = {
    currentUser: PropTypes.shape({})
  };

  static defaultProps = {
    currentUser: {}
  };

  constructor() {
    super();
    this.state = {
      collapse: false
    };
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const { currentUser } = this.props;
    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" onClick={this.toggle}>
          {/* <img className="topbar__avatar-img" src={Ava} alt="avatar" /> */}
          <p className="topbar__avatar-name">{`${currentUser.firstName} ${
            currentUser.lastName
          }`}
          </p>
          <DownIcon className="topbar__icon" />
        </button>
        {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            {/* <TopbarMenuLink title="" icon="list" path="/pages/one" />
            <TopbarMenuLink title="" icon="inbox" path="/pages/two" /> */}
            <div className="topbar__menu-divider" />
            <TopbarMenuLink title="Exit Admin" icon="home" path="/dashboard" />
            <TopbarMenuLink title="Log Out" icon="exit" path="/logout" />
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapState = state => ({
  currentUser: store.select.user.currentUser(state)
});

export default connect(mapState)(TopbarProfile);
