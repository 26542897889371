import React from 'react';
import * as PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import CallRecordingTrackerList from './List';

const CallRecordingTrackerRoute = ({ match }) => (
  <Switch>
    <Route path={`${match.path}`} component={CallRecordingTrackerList} />
  </Switch>
);

CallRecordingTrackerRoute.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired
};

export default CallRecordingTrackerRoute;
