import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ title, subhead }) => (
  <div className="card__title">
    <h5 className="bold-text">{title}</h5>
    {subhead && <h5 className="subhead">{subhead}</h5>}
  </div>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
  subhead: PropTypes.string
};

Title.defaultProps = {
  subhead: null
};

export default Title;
