import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import fp from 'lodash/fp';
import PropTypes from 'prop-types';

import { Card, CardBody } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import Styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';

import Tag from '../../../../shared/components/Tags';
import store from '../../../../redux/store';

const StyledComponentTitle = Styled.span`
  width: 20%;
  flex: 1;
`;

const StyledToggleLabel = Styled.span`
  text-align: right;
  flex: 1;
  padding-right: 0.5rem; // let's be consistent on our unit of measure :smile:
  flex-basis: auto;
`;

const StyledToggleContainer = Styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
`;

// #70bbfd is from variable.scss named color-blue
const StyledCheckbox = Styled(Checkbox)`
  color: #70bbfd !important;
  padding: 0px !important;
`;

const CallRecordingTags = ({
  fetchTags,
  callRecordingId,
  callRecordingTagValues,
  isCallRecordingLoaded,
  isPlaybackToEnd,
  currentTime,
  setPlayback
}) => {
  useEffect(() => {
    if (isCallRecordingLoaded) {
      fetchTags(callRecordingId);
    }
  }, [isCallRecordingLoaded]);

  if (fp.isEmpty(callRecordingTagValues)) {
    return <span />;
  }

  const callRecordingTags = (tags, id) =>
    fp.map((tag) => {
      const time = isPlaybackToEnd ? tag.start : tag.end;

      return (
        <Tag
          redirect={`/call-recordings/${id}?time=${time}`}
          tagName={tag.name}
          isActive={currentTime <= tag.end && currentTime >= tag.start}
        />
      );
    })(tags);

  const handleCheckbox = e => setPlayback(e.target.checked);

  return (
    <Card className="card--not-full-height">
      <CardBody>
        <StyledToggleContainer>
          <StyledComponentTitle>
            <h6 className="bold-text">
              TAGS
            </h6>
          </StyledComponentTitle>
          <StyledToggleLabel>
            <h6 className="bold-text">
              Include Message
            </h6>
          </StyledToggleLabel>
          <StyledCheckbox
            defaultChecked={isPlaybackToEnd}
            onChange={handleCheckbox}
          />
        </StyledToggleContainer>
        <Grid container>
          <Grid item xs={12}>
            {callRecordingTags(callRecordingTagValues, callRecordingId)}
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  );
};

CallRecordingTags.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  fetchTags: PropTypes.func.isRequired,
  callRecordingId: PropTypes.number.isRequired,
  callRecordingTagValues: PropTypes.shape({}).isRequired,
  isCallRecordingLoaded: PropTypes.bool.isRequired,
  currentTime: PropTypes.number.isRequired,
  setPlayback: PropTypes.func.isRequired,
  isPlaybackToEnd: PropTypes.bool.isRequired
};

const mapDispatchToProps = dispatch => ({
  fetchTags: dispatch.callRecordingTags.fetchCallRecordingTags,
  setPlayback: dispatch.globalConfig.setPlayback
});

const mapStateToProps = (state, ownProps) => {
  const callRecordingId = fp.get('id', ownProps);
  const callRecording = store.select
    .callRecordings.getCallRecording(callRecordingId)(state) || {};

  return {
    callRecordingId,
    callRecordingTagValues:
      store
        .select
        .callRecordingTags
        .getTagsForCallRecordingId(callRecordingId)(state) || [],
    isCallRecordingLoaded: !fp.isEmpty(callRecording),
    isPlaybackToEnd: store.select.globalConfig.getIsPlaybackToEndValue(state)
  };
};

export default
connect(
  mapStateToProps,
  mapDispatchToProps
)(CallRecordingTags);
