import fp from 'lodash/fp';
import { isValidEmail, containsNumber } from '../../../utils/validation';

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    { fieldName: 'first_name', label: 'First Name' },
    { fieldName: 'last_name', label: 'Last Name' },
    { fieldName: 'email', label: 'Email' },
    { fieldName: 'password', label: 'Password' },
    { fieldName: 'user_type', label: 'User type' }
  ];

  requiredFields.forEach((reqField) => {
    if (!fp.has(reqField.fieldName)(values)) {
      errors[reqField.fieldName] = `${reqField.label} field shouldn't be empty`;
    }
    if (!isValidEmail(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (containsNumber(values.first_name)) {
      errors.first_name = 'Invalid First Name';
    }
    if (containsNumber(values.last_name)) {
      errors.last_name = 'Invalid Last Name';
    }
  });

  return errors;
};

export default validate;
