import React, { useState } from 'react';
import PlusCircleIcon from 'mdi-react/PlusCircleIcon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import PropTypes from 'prop-types';

const Popovers = ({
  header, body, toolTipTitle, isDisabled, popover
}) => {
  const [popOverOpen, setPopOverOpen] = useState(false);
  const toggle = () => setPopOverOpen(!popOverOpen);

  return (
    <div>
      <Tooltip title={toolTipTitle}>
        <div>
          <IconButton id="Popover" onClick={toggle} disabled={isDisabled}>
            <PlusCircleIcon />
          </IconButton>
        </div>
      </Tooltip>
      <Popover
        className={popover}
        placement="right"
        isOpen={popOverOpen}
        target="Popover"
        toggle={toggle}
      >
        <PopoverHeader>{header}</PopoverHeader>
        <PopoverBody>
          {body}
        </PopoverBody>
      </Popover>
    </div>
  );
};

Popovers.propTypes = {
  header: PropTypes.string,
  toolTipTitle: PropTypes.string,
  body: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.array
  ]),
  isDisabled: PropTypes.bool.isRequired,
  popover: PropTypes.string
};

Popovers.defaultProps = {
  body: [],
  header: '',
  popover: '',
  toolTipTitle: ''
};

export default Popovers;
