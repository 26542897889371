import React from 'react';
import * as PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import Dashboard from './Dashboard';
import TrackerRoute from './CallRecordingTracker/Route';
import UserManagementRoute from './UserManagement/Route';
import FilterManagementRoute from './FilterManagement/Route';
import { isSuperAdmin } from '../../utils/auth';

const AdminRoute = ({ match, currentUser }) => {
  const CallRecordingTrackerRoute = isSuperAdmin(currentUser) ? (<Route
    path={`${match.path}/call-recording-tracker`}
    component={TrackerRoute}
  />) : <span />;

  return (
    <Switch>
      <Route path={`${match.path}/user-management`} component={UserManagementRoute} />
      <Route path={`${match.path}/filter-management`} component={FilterManagementRoute} />
      <Route path={`${match.path}/dashboard`} component={Dashboard} />
      {CallRecordingTrackerRoute}
      <Route path={`${match.path}`} component={Dashboard} />
    </Switch>
  );
};

AdminRoute.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired,
  currentUser: PropTypes.shape({})
};

AdminRoute.defaultProps = {
  currentUser: {}
};

export default AdminRoute;
