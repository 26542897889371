const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = 'Required';
  }
  if (!values.confirm_password) {
    errors.confirm_password = 'Required';
  } else if (values.confirm_password !== values.password) {
    errors.confirm_password = 'Password mismatched';
  }

  return errors;
};

export default validate;
