import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import PasswordResetIcon from 'mdi-react/PasswordResetIcon';
import ForgotPasswordIcon from 'mdi-react/ForgotPasswordIcon';

import {
  Form,
  FormGroup,
  Label,
  FormField,
  FormGroupField
} from '../../../shared/components/Form';
import RenderField from '../../../shared/components/form/RenderField';
import SubmitButton from './SubmitButton';
import resetPasswordFormValidate from './validate';

const ResetPasswordForm = ({ onSubmit }) => (
  <Form className="form" orientation="vertical" onSubmit={onSubmit}>
    <FormGroup style={{ marginTop: '15px' }}>
      <Label>Password</Label>
      <FormGroupField>
        {/* // TODO: put this in shared components */}
        <div className="form__form-group-icon">
          <PasswordResetIcon />
        </div>
        <FormField
          name="password"
          placeholder="Enter Password"
          component={RenderField}
          type="password"
        />
      </FormGroupField>
    </FormGroup>
    <FormGroup style={{ marginTop: '15px' }}>
      <Label>Confirm Password</Label>
      <FormGroupField style={{ marginTop: '5px' }}>
        <div className="form__form-group-icon">
          <ForgotPasswordIcon />
        </div>
        <FormField
          name="confirm_password"
          placeholder="Confirm Password"
          component={RenderField}
          type="password"
        />
      </FormGroupField>
    </FormGroup>
    <SubmitButton />
  </Form>
);

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'reset_password_form',
  validate: resetPasswordFormValidate
})(ResetPasswordForm);
