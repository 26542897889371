/* eslint-disable max-lines */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import fp from 'lodash/fp';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { reduxForm, isDirty, isPristine, isSubmitting } from 'redux-form';
import GreaterThanOrEqualIcon from 'mdi-react/GreaterThanOrEqualIcon';
import LessThanOrEqualIcon from 'mdi-react/LessThanOrEqualIcon';
import { CardBody, CardFooter, Card, Col, Row } from 'reactstrap';
import {
  Form,
  FormGroup,
  FormGroupField,
  Label,
  FormField
} from '../../../../shared/components/Form';
import DatePicker from '../../../../shared/components/form/DatePicker';
import Panel from '../../../../shared/components/Panel';
import KSelect from '../../../../shared/components/KSelect';
import Modal from '../../../../shared/components/Modal';
import ActionButton from '../../../../shared/components/ActionButton';
import { getUserRole } from '../../../../redux/models/helpers';
import store from '../../../../redux/store';
import PanelToolbar from './PanelToolbar';

// Fields
import Agent from './Agent';
import Client from './Client';
import Process from './Process';
import DataField from './DataField';

const StyledFilterPanel = styled.div`
  .Select-menu-outer {
    max-height: 350px;
  },
  .Select-menu {
    max-height: 350px;
  }
`;

const FilterPanel = ({
  addFilterField,
  currentUser,
  fetchDataFields,
  filterDropdownValues,
  filterList,
  isCallRecordingsLoading,
  isDataFieldsMappingLoading,
  isFilterApplied,
  isFormDirty,
  isFormSubmitting,
  isFilterExpanded,
  isFormPristine,
  isRehydrated,
  onFilter,
  reset,
  resetFilterDropDownValues,
  resetDataFieldDropDownValues,
  resetSection,
  resetAddedFilterColumn,
  resetFilters,
  resetSelectedProcess,
  setIsExpandedState,
  initialValues,
  userRole
}) => {
  const debouncedOnFilter = fp.debounce(500, onFilter, { trailing: true });

  const renderFilterStatus = useCallback(() => {
    if (!isFilterApplied || !isFormDirty) {
      return '';
    }

    return isCallRecordingsLoading ? 'Applying filters...' : 'filters applied';
  }, [isFilterApplied, isFormDirty, isCallRecordingsLoading]);

  const customPanelToolBar = (isCollapse) => {
    const arrowAction = () => (isCollapse ? 'arrow-up' : 'arrow-down');

    return (
      <PanelToolbar
        title="Filters"
        icon={arrowAction()}
        status={renderFilterStatus()}
      />
    );
  };

  const clearDataFieldInputs = () => {
    // static for now we have a maximum of 10 fields
    const dataMappingInputFields = [...Array(10).keys()]
      .map(value => `data${value + 1}[in]`);

    // TODO we need to revisit why clearFields isn't working for redux-form
    dataMappingInputFields.forEach(fieldName => resetSection(fieldName));
  };

  const handleFilterOnChange = (value) => {
    clearDataFieldInputs();
    resetDataFieldDropDownValues();
    addFilterField(value);

    // update call recordings result
    onFilter(value);
  };

  const handleFilterBlur = (event) => {
    event.preventDefault();

    if (!fp.isEmpty(event.target.value)) {
      resetFilterDropDownValues();
    }
  };

  const renderClientField = () =>
    // only show clientField if userRole level is > 3 OR
    // user is allowed to view multiple clients
    (userRole.level > 3 || fp.size(currentUser.userRoles) > 1) && (
      <Col lg={2} md={4} xs={6}>
        <Client onFilter={onFilter} />
      </Col>
    );

  if (!isRehydrated) {
    return <span />;
  }

  return (
    <Panel
      className="search-filter"
      title="Filter Panel"
      hasArrowIcon
      toolbar={customPanelToolBar}
      isCollapsible
      autoScrollLength={15}
      onExpandChange={setIsExpandedState}
      isExpanded={isFilterExpanded}
    >
      <StyledFilterPanel>
        <Form className="form" orientation="vertical" onSubmit={() => {}}>
          <Card style={{ paddingBottom: '0px' }}>
            <CardBody style={{ padding: '15px 15px 0px 15px' }}>
              <Row>
                <Col lg={3} md={6}>
                  <Label>Date Range</Label>
                  <FormGroup style={{ display: 'flex' }}>
                    <FormField
                      name="start_time[gte]"
                      component={DatePicker}
                      onChange={debouncedOnFilter}
                    />
                    <small style={{ padding: '8px' }}>To</small>
                    <FormField
                      name="start_time[lte]"
                      component={DatePicker}
                      onChange={debouncedOnFilter}
                    />
                  </FormGroup>
                </Col>
                <Col lg={3} md={6}>
                  <Label>Call Length</Label>
                  <FormGroup style={{ display: 'flex' }}>
                    <FormGroupField>
                      <div className="form__form-group-icon">
                        <GreaterThanOrEqualIcon />
                      </div>
                      <FormField
                        name="call_length_seconds[gte]"
                        component="input"
                        placeholder=""
                        onChange={debouncedOnFilter}
                      />
                    </FormGroupField>
                    <small style={{ padding: '8px' }}>To</small>
                    <FormGroupField>
                      <div className="form__form-group-icon">
                        <LessThanOrEqualIcon />
                      </div>
                      <FormField
                        name="call_length_seconds[lte]"
                        component="input"
                        placeholder=""
                        onChange={debouncedOnFilter}
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
                {renderClientField()}
                <Col lg={2} md={4} xs={6}>
                  <Agent onFilter={onFilter} />
                </Col>
                <Col lg={2} md={4} xs={6}>
                  <Process onFilter={onFilter} />
                </Col>
                <DataField onFilter={onFilter} dataFields={filterDropdownValues} />
              </Row>
            </CardBody>
            <CardFooter className="filter-panel-footer">
              <Row>
                <Col style={{ textAlign: 'center' }}>
                  <ActionButton
                    title="Clear Filter"
                    color="danger"
                    className="filter-panel-button"
                    size="sm"
                    isDisabled={(isFormPristine || isFormSubmitting) &&
                      fp.isEmpty(initialValues.process_id)}
                    onClick={() => {
                      resetSection('process_id');
                      resetSelectedProcess();
                      resetFilters();
                      resetFilterDropDownValues();
                      reset();
                      onFilter();
                      resetAddedFilterColumn();
                    }}
                  />
                  <Modal
                    header
                    color="primary"
                    title="Select Filters"
                    buttonTitle={fp.isEmpty(initialValues.add_filter) ?
                        'Add Filter' : 'Edit Filter'}
                    buttonClassName="filter-panel-button"
                    toolTipMessage="Select a process to add more filters"
                    size="sm"
                    isButtonDisable={fp.isEmpty(initialValues.process_id)}
                    message={
                      <FormGroup>
                        <Label>Filters</Label>
                        <FormField
                          className="select-filter-modal"
                          component={KSelect}
                          isLoading={isDataFieldsMappingLoading}
                          name="add_filter"
                          options={filterList}
                          onBlur={handleFilterBlur}
                          onChange={value => handleFilterOnChange(value)}
                          onFocus={() =>
                              fp.isEmpty(filterList) &&
                              fetchDataFields(initialValues.process_id)
                            }
                          placeHolder="Select Filter..."
                          isMulti
                        />
                      </FormGroup>}
                  />
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Form>
      </StyledFilterPanel>
    </Panel>
  );
};

const optionPropType = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string
});

FilterPanel.propTypes = {
  addFilterField: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({}),
  filterDropdownValues: PropTypes.arrayOf(optionPropType),
  fetchDataFields: PropTypes.func.isRequired,
  filterList: PropTypes.arrayOf(optionPropType),
  isCallRecordingsLoading: PropTypes.bool.isRequired,
  isDataFieldsMappingLoading: PropTypes.bool.isRequired,
  isFilterApplied: PropTypes.bool.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  isFormSubmitting: PropTypes.bool.isRequired,
  isFilterExpanded: PropTypes.bool.isRequired,
  isFormPristine: PropTypes.bool.isRequired,
  isRehydrated: PropTypes.bool.isRequired,
  onFilter: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  resetDataFieldDropDownValues: PropTypes.func.isRequired,
  resetSection: PropTypes.func.isRequired,
  resetAddedFilterColumn: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  resetFilterDropDownValues: PropTypes.func.isRequired,
  resetSelectedProcess: PropTypes.func.isRequired,
  setIsExpandedState: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    process_id: PropTypes.shape({}),
    add_filter: PropTypes.arrayOf(optionPropType)
  }),
  userRole: PropTypes.shape({
    clientId: PropTypes.number,
    clientKey: PropTypes.string,
    level: PropTypes.number,
    role: PropTypes.string
  }).isRequired
};
FilterPanel.defaultProps = {
  currentUser: {},
  filterDropdownValues: [],
  filterList: [],
  initialValues: {}
};

const mapDispatch = dispatch => ({
  addFilterField: dispatch.globalConfig.addFilter,
  fetchDataFields: dispatch.filterPanel.fetchDataFieldMapping,
  resetDataFieldDropDownValues: dispatch.filterPanel.resetDataFieldValues,
  resetAddedFilterColumn: dispatch.globalConfig.resetAddedFilter,
  resetFilters: dispatch.filterPanel.resetFilterFormValues,
  resetSelectedProcess: dispatch.globalConfig.resetSelectedProcess,
  resetFilterDropDownValues: dispatch.filterPanel.resetDataFields,
  setIsExpandedState: dispatch.globalConfig.storeIsExpandedState
});

const mapStateToProps = (state) => {
  const currentUser = store.select.user.currentUser(state);
  const userRole = getUserRole(currentUser, currentUser.organizationId);

  const selectedProcessValue = store.select.globalConfig.selectedProcessValue(state);
  const selectedFilterValues = store.select.globalConfig.addedFilterValue(state);

  // Set initial value for filters and process
  // Note: we did not use camelCase for initialValues because we need to match
  // to form name
  const initialValues = {
    process_id: fp.isEmpty(selectedProcessValue) ? null : selectedProcessValue,
    add_filter: selectedFilterValues
  };

  return {
    initialValues,
    currentUser,
    filterList: store.select.filterPanel.getDataFields(state),
    filterDropdownValues: selectedFilterValues,
    isCallRecordingsLoading: state.loading.models.callRecordings,
    isDataFieldsMappingLoading: state.loading.effects.filterPanel
      .fetchDataFieldMapping,
    isFilterApplied: state.callRecordings.isFilterApplied,
    isFilterExpanded: store.select.globalConfig.getIsFilterExpandedState(state),
    isFormDirty: isDirty('call_recording_filter_form')(state),
    isFormPristine: isPristine('call_recording_filter_form')(state),
    isFormSubmitting: isSubmitting('call_recording_filter_form')(state),
    isRehydrated: state.rehydrate.rehydrated,
    userRole
  };
};

export default fp.flow(
  reduxForm({
    form: 'call_recording_filter_form',
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    destroyOnUnmount: false
  }),
  connect(
    mapStateToProps,
    mapDispatch
  )
)(FilterPanel);
