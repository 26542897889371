import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import fp from 'lodash/fp';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { getSelectedProcess } from '../../../redux/models/helpers';
import Popovers from '../../../shared/components/Popovers';
import Toolbar from '../../../shared/components/KTables/TableToolbar';
import store from '../../../redux/store';

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  popover: {
    zIndex: '999'
  },
  addColumnWidth: {
    width: '100%'
  }
});

const TableToolbar = ({
  classes, selectedProcess, dataFields,
  addFieldToShownColumns,
  loadDataFields,
  callRecordingColumns, removeFieldFromShownColumns,
  onRefreshClick, onLoading,
  message
}) => {
  const shownColumnsKeys = fp.map('key', callRecordingColumns);
  const shownColumnsMap = fp.flow(
    fp.toPairs,
    fp.reduce(
      (map, [key]) => ({
        ...map,
        [key]: fp.includes(key)(shownColumnsKeys)
      })
      , {}
    )
  )(dataFields);

  const handleCheckBoxSelection = (e) => {
    if (fp.isNil(selectedProcess)) {
      return null;
    }
    return fp.getOr(false, e.target.name)(shownColumnsMap)
      ? removeFieldFromShownColumns(selectedProcess, {
        key: e.target.name,
        name: e.target.value
      })
      : addFieldToShownColumns(selectedProcess, {
        key: e.target.name,
        name: e.target.value
      });
  };


  useEffect(() => {
    if (!fp.isNil(selectedProcess) && fp.isEmpty(dataFields)) {
      loadDataFields(selectedProcess);
    }
  }, [selectedProcess, dataFields]);

  const toolTipTitle = !fp.isEmpty(dataFields)
    ? 'Add Column'
    : 'Select a process from the filter panel to add columns to the table';

  const columnOptionsList = fp.flow(
    fp.toPairs,
    fp.map(([key, { displayName }]) => {
      const isShown = fp.getOr(false, key)(shownColumnsMap);
      const controlBox = (
        <Checkbox
          name={key}
          value={displayName}
          color="primary"
          key={key}
          checked={isShown}
          onChange={handleCheckBoxSelection}
        />
      );

      return (
        <FormControlLabel
          key={key}
          control={controlBox}
          classes={{
            root: classes.addColumnWidth
          }}
          label={displayName}
        />
      );
    })
  )(dataFields);

  return (
    <Toolbar
      isLoading={onLoading}
      message={message}
      onRefreshClick={onRefreshClick}
    >
      <div className={classes.actions}>
        <Popovers
          header="Add Column"
          popover={classes.popover}
          toolTipTitle={toolTipTitle}
          isDisabled={fp.isEmpty(dataFields) || dataFields.length === 0}
          body={columnOptionsList}
        />
      </div>
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  addFieldToShownColumns: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  callRecordingColumns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataFields: PropTypes.arrayOf(PropTypes.shape({})),
  loadDataFields: PropTypes.func.isRequired,
  message: PropTypes.string,
  onRefreshClick: PropTypes.func.isRequired,
  onLoading: PropTypes.bool.isRequired,
  removeFieldFromShownColumns: PropTypes.func.isRequired,
  selectedProcess: PropTypes.number
};

TableToolbar.defaultProps = {
  dataFields: [],
  message: '',
  selectedProcess: null
};

const dispatchToProps = dispatch => ({
  addFieldToShownColumns: dispatch.globalConfig.addCallRecordingColumnHandler,
  loadDataFields: dispatch.dataField.fetchDataFields,
  removeFieldFromShownColumns: dispatch
    .globalConfig
    .removeCallRecordingColumnHandler
});

const mapStateToProps = state => ({
  callRecordingColumns: store.select.globalConfig
    .getCallRecordingColumnsBySelectedProcess(state),
  selectedProcess: getSelectedProcess(state),
  dataFields: store.select.dataField.getByProcessId(state, getSelectedProcess(state))
});


export default fp.flow(
  connect(
    mapStateToProps,
    dispatchToProps
  ),
  withStyles(toolbarStyles)
)(TableToolbar);
