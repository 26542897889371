import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';

const Section = ({ children }) => (
  <Card>
    <CardBody>{children}</CardBody>
  </Card>
);

Section.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired
};

export default Section;
