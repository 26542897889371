import React from 'react';
import PropTypes from 'prop-types';
// import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { Badge } from 'reactstrap';

const toolbarStyles = () => ({
  panelTitle: {
    width: '100%',
    padding: '10px'
  }
});

const PanelToolbar = (props) => {
  const {
    children,
    title,
    icon,
    label,
    subhead,
    refresh,
    classes
  } = props;

  return (
    <React.Fragment>
      {refresh ? (
        <div className="panel__refresh">
          <LoadingIcon />
        </div>
      ) : ''}
      <div className={`panel__title ${classes.panelTitle}`}>
        <h5 className="bold-text">
          {icon ? <span className={`panel__icon lnr lnr-${icon}`} /> : ''}
          {title}
          <Badge className="panel__label">{label}</Badge>
        </h5>
        <h5 className="subhead">{subhead}</h5>
      </div>

      {children}
    </React.Fragment>
  );
};

PanelToolbar.propTypes = {
  classes: PropTypes.func.isRequired,
  children: PropTypes.element,
  // onCollapse: PropTypes.func,
  title: PropTypes.string.isRequired,
  // status: PropTypes.string,
  subhead: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  refresh: PropTypes.bool
};

PanelToolbar.defaultProps = {
  // onCollapse: () => {},
  // status: '',
  icon: '',
  children: null,
  subhead: '',
  label: '',
  refresh: false
};

export default withStyles(toolbarStyles)(PanelToolbar);
