import React from 'react';
import { Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import CallRecordingTable from '../../components/CallRecordings/List';

const Dashboard = props => (
  <Container className="callrecording">
    <Row>
      <CallRecordingTable onRowClick={props.history.push} />
    </Row>
  </Container>
);

Dashboard.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(Dashboard);
