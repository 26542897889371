import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import fp from 'lodash/fp';

const KSelect = ({
  disabled,
  stayOpen,
  value,
  placeHolder,
  options,
  isLoading,
  customStyle,
  noResultsText,
  onChange,
  onSearch,
  name,
  isMulti,
  className,
  onFocus,
  onBlur: handleBlur
}) => {
  const debouncedOnFilter = fp.debounce(500, onChange, { trailing: true });
  const debouncedOnSearch = fp.debounce(500, onSearch, { trailing: true });
  const debouncedOnFocus = fp.debounce(500, onFocus, { trailing: true });

  const handleOnInputChange = (resp) => {
    if (!fp.isEmpty(options)) {
      debouncedOnFilter(resp);
    }

    // input field value is reset
    if (isMulti && fp.isEmpty(resp)) {
      debouncedOnFocus({ value: '' });
    }
  };

  const handleOnSearchInputChange = (resp) => {
    if (!fp.isEmpty(resp)) {
      debouncedOnSearch(resp);
    }
  };

  const handleFocus = (resp) => {
    if (!fp.isEmpty(resp)) {
      debouncedOnFocus(resp);
    }
  };

  const shouldResetInput = fp.isNil(handleBlur);
  return (
    <Select
      name={name}
      isSearchable
      styles={customStyle}
      closeOnSelect={!stayOpen}
      disabled={disabled}
      multi={isMulti}
      onChange={handleOnInputChange}
      options={options}
      placeholder={placeHolder}
      value={value}
      onInputChange={handleOnSearchInputChange}
      isLoading={isLoading}
      noResultsText={noResultsText}
      className={className}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onCloseResetsInput={shouldResetInput}
    />
  );
};

KSelect.propTypes = {
  disabled: PropTypes.bool,
  stayOpen: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string
  })),
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })),
    PropTypes.string
  ]),
  placeHolder: PropTypes.string,
  isLoading: PropTypes.bool,
  noResultsText: PropTypes.string,
  onSearch: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  customStyle: PropTypes.shape({})
};

KSelect.defaultProps = {
  disabled: false,
  stayOpen: false,
  options: [],
  placeHolder: '',
  noResultsText: '',
  isLoading: false,
  isMulti: false,
  className: '',
  customStyle: {},
  onFocus: () => {},
  onSearch: () => {},
  onBlur: null,
  value: []
};

const renderKSelect = props => (
  <KSelect {...(props.input || null)} {...props} />
);

export default renderKSelect;
