import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Row, Col, Container } from 'reactstrap';
import fp from 'lodash/fp';
import store from '../../../redux/store';
import Title from '../../../shared/components/Title';
import Section from '../../../shared/components/Section';
import {
  debouncedShowNotification as showNotification,
  destroyNotification,
  instantiateNotification
} from '../../../shared/components/notification/';
import { BasicNotification } from '../../../shared/components/Notification';

import validate from './validate';
import RenderField from '../../../shared/components/form/RenderField';

import { Form, FormGroup, Label, FormField } from '../../../shared/components/Form';

const UserForm = ({
  handleSubmit,
  submitting,
  submitSucceeded,
  submitFailed,
  errorResponse
}) => {
  const notify = useCallback((color, title, message) => {
    showNotification({
      notification: <BasicNotification color={color} title={title} message={message} />,
      position: 'right-up'
    });
  }, [submitSucceeded, submitFailed]);


  if (submitSucceeded) {
    notify('success', 'User Successfully Created', '');

    destroyNotification();
    return <Redirect to="/admin/user-management/list" />;
  }

  if (submitFailed) {
    notify('danger', 'Unsuccessful', errorResponse.message);
  }

  return (
    <Col xs={6} md={6} lg={6} xl={12}>
      <Section>
        <Title
          title="User Details"
          subhead={submitting ? 'submitting...' : 'Fill up the form.'}
        />

        <Form className="form" orientation="vertical" onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col md={8} xs={8} lg={8} xl={8}>
                <FormGroup>
                  <Label>First Name: </Label>
                  <FormField name="first_name" component={RenderField} type="text" />
                </FormGroup>
                <FormGroup>
                  <Label>Last Name:</Label>
                  <FormField name="last_name" component={RenderField} type="text" />
                </FormGroup>
                <FormGroup>
                  <Label>E-mail: </Label>
                  <FormField name="email" component={RenderField} type="email" />
                </FormGroup>
                <FormGroup>
                  <Label>Password: </Label>
                  <FormField name="password" component={RenderField} type="password" />
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </Form>
      </Section>
    </Col>
  );
};

UserForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  errorResponse: PropTypes.shape({ message: PropTypes.string })
};

UserForm.defaultProps = {
  errorResponse: {}
};

const mapDispatch = dispatch => ({
  onSubmit: dispatch.user.createUser
});

const mapState = state => ({
  errorResponse: store.select.user.error(state)
});

export default fp.flow(
  lifecycle({
    componentDidMount() {
      instantiateNotification();
    }
  }),
  reduxForm({
    form: 'user_form',
    validate
  }),
  connect(
    mapState,
    mapDispatch
  )
)(UserForm);
