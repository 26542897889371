import fp from 'lodash/fp';
import api from './api';
import { camelizeKeys } from './helpers';

const dataFieldMappingApi = api('/data-fields-mapping');

export const fetchDataFieldMapping = async (data = {}) => {
  const dataFieldMappings = await dataFieldMappingApi.get('', { params: data });
  return fp.flow(
    fp.getOr({}, 'data.data'),
    camelizeKeys
  )(dataFieldMappings);
};
